import * as React from "react";
import { EGDSLayoutGrid } from "@egds/react-core/layout-grid";
import { EGDSText } from "@egds/react-core/text";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { DecorateEditorialHtml } from "src/components/flexComponents/Editorial/ContentUtilities";
import { EditorialModel } from "src/typings/microserviceModels/content-flex-module";
import { ViewLarge, Viewport, ViewSmall } from "@shared-ui/viewport-context";

export const CitiHeaderSection = (props: { items: EditorialModel[] }) => {
  const { items } = props;
  if (items.length !== 2) {
    return null;
  }

  return (
    <EGDSLayoutGrid space="two">
      <Viewport>
        <ViewSmall>
          <EGDSText size={600} align="center" weight="bold">
            <EGDSSpacing padding={{ blockstart: "four" }}>
              <DecorateEditorialHtml text={items[0].text} />
            </EGDSSpacing>
          </EGDSText>
        </ViewSmall>
        <ViewLarge>
          <EGDSText size={700} align="center" weight="bold">
            <EGDSSpacing padding={{ blockstart: "four" }}>
              <DecorateEditorialHtml text={items[0].text} />
            </EGDSSpacing>
          </EGDSText>
        </ViewLarge>
      </Viewport>
      <EGDSText align="center" size={300} weight="regular">
        <EGDSSpacing padding={{ blockend: "six" }}>
          <DecorateEditorialHtml text={items[1].text} />
        </EGDSSpacing>
      </EGDSText>
    </EGDSLayoutGrid>
  );
};
export default CitiHeaderSection;
