import * as React from "react";
import { DecorateEditorialHtml } from "src/components/flexComponents/Editorial/ContentUtilities";
import { EGDSText } from "@egds/react-core/text";
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { EGDSLayoutGrid, EGDSLayoutGridItem } from "@egds/react-core/layout-grid";
import { EditorialModel } from "src/typings/microserviceModels/content-flex-module";
import { EGDSSpacing } from "@egds/react-core/spacing";

const CONTENT_ID_COUNT = 4;
export const CardComparisonNoFeeAddedBenefitsEditorial = (props: { items: EditorialModel[] }) => {
  const { items } = props;

  if (items.length !== CONTENT_ID_COUNT) {
    return null;
  }

  const [noFeeAddedBenefitsIcon, noFeeAddedBenefitsOne, noFeeAddedBenefitsTwo, noFeeAddedBenefitsThree] = React.useMemo(
    () => items.map((item) => <DecorateEditorialHtml key={item.id} text={item.text} />),
    []
  );

  return (
    <EGDSSpacing
      padding={{ medium: { inline: "twelve" }, large: { inline: "unset", inlinestart: "twelve" } }}
      margin={{ medium: { inline: "twelve" }, large: { inline: "unset", inlinestart: "six" } }}
    >
      <div>
        <EGDSLayoutGrid>
          <EGDSLayoutGridItem>
            <EGDSLayoutFlex space="three" alignItems="center">
              <EGDSLayoutFlexItem>
                <div>{noFeeAddedBenefitsIcon}</div>
              </EGDSLayoutFlexItem>
              <EGDSLayoutFlexItem>
                <div>
                  <EGDSText size={300} weight="medium" align="left">
                    <div>{noFeeAddedBenefitsOne}</div>
                  </EGDSText>
                </div>
              </EGDSLayoutFlexItem>
            </EGDSLayoutFlex>
          </EGDSLayoutGridItem>
          <EGDSSpacing padding={{ blockstart: "three" }}>
            <EGDSLayoutGridItem>
              <EGDSLayoutFlex space="three" alignItems="center">
                <EGDSLayoutFlexItem>
                  <div>{noFeeAddedBenefitsIcon}</div>
                </EGDSLayoutFlexItem>
                <EGDSLayoutFlexItem>
                  <div>
                    <EGDSText size={300} weight="medium" align="left">
                      <div>{noFeeAddedBenefitsTwo}</div>
                    </EGDSText>
                  </div>
                </EGDSLayoutFlexItem>
              </EGDSLayoutFlex>
            </EGDSLayoutGridItem>
          </EGDSSpacing>
          <EGDSSpacing padding={{ blockstart: "three", blockend: "six" }}>
            <EGDSLayoutGridItem>
              <EGDSLayoutFlex space="three" alignItems="center">
                <EGDSLayoutFlexItem>
                  <div>{noFeeAddedBenefitsIcon}</div>
                </EGDSLayoutFlexItem>
                <EGDSLayoutFlexItem>
                  <div>
                    <EGDSText size={300} weight="medium" align="left">
                      <div>{noFeeAddedBenefitsThree}</div>
                    </EGDSText>
                  </div>
                </EGDSLayoutFlexItem>
              </EGDSLayoutFlex>
            </EGDSLayoutGridItem>
          </EGDSSpacing>
        </EGDSLayoutGrid>
      </div>
    </EGDSSpacing>
  );
};

export default CardComparisonNoFeeAddedBenefitsEditorial;
