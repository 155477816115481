import * as React from "react";
import { EGDSPrimaryButton } from "@egds/react-core/button";
import { useLocation } from "react-router";
import { EGDSSticky } from "@egds/react-core/sticky";
import { EGDSText } from "@egds/react-core/text";
import {
  EGDSLayoutGrid,
  EGDSLayoutGridItem,
  EGDSLayoutConditionalGridSpan,
  EGDSLayoutGridColumnRow,
} from "@egds/react-core/layout-grid";
import { cardLink } from "src/stores/wizard/config/overrideConfigBySiteId/siteIds/siteId1";
import { ViewExtraLarge, ViewLarge, Viewport } from "@shared-ui/viewport-context";
import { DecorateEditorialHtml, returnCitiCode } from "src/components/flexComponents/Editorial/ContentUtilities";
import { EditorialModel } from "src/typings/microserviceModels/content-flex-module";
import { useLocalization } from "@shared-ui/localization-context";
import { EGDSSpacing } from "@egds/react-core/spacing";

export const CitiDesktopStickyBar = (props: { items: EditorialModel[] }) => {
  const { formatText } = useLocalization();
  const { search: searchUrl } = useLocation();
  const { items } = props;
  const imageColSpan: EGDSLayoutConditionalGridSpan = { small: 2, medium: 2, large: 2, extra_large: 2 };
  const textColSpan: EGDSLayoutConditionalGridSpan = { small: 4, medium: 4, large: 4, extra_large: 4 };
  const separatorColSpan: EGDSLayoutConditionalGridSpan = { small: 1, medium: 1, large: 1, extra_large: 1 };
  const buttonColSpan: EGDSLayoutConditionalGridSpan = { small: 3, medium: 3, large: 3, extra_large: 3 };

  const gridColumns = {
    extra_large: 12 as EGDSLayoutGridColumnRow,
    large: 12 as EGDSLayoutGridColumnRow,
    medium: 12 as EGDSLayoutGridColumnRow,
    small: 12 as EGDSLayoutGridColumnRow,
  };

  if (items.length !== 5) {
    return null;
  }
  const [feeText, separator, feeImage, noFeeImage, noFeeText] = items.map((item) => (
    <DecorateEditorialHtml key={item.id} text={item.text} />
  ));
  const applyNowText = formatText("grid3.citiDesktopStickybar.applyNow.text");
  const citiCode = returnCitiCode(searchUrl);
  const feeCardApplyNowLink = citiCode ? cardLink.feeCardLink + `&citiCode=${citiCode}` : cardLink.feeCardLink;
  const noFeeCardApplyNowLink = citiCode ? cardLink.noFeeCardLink + `&citiCode=${citiCode}` : cardLink.noFeeCardLink;

  return (
    <Viewport>
      <ViewExtraLarge>
        <section>
          <EGDSSticky hidden zIndex={1}>
            <div className="uitk-button-old-raised">
              <EGDSLayoutGrid columns={{ auto_fill: "sixteen" }}>
                <EGDSLayoutGridItem colSpan={imageColSpan}>
                  <div>
                    <EGDSSpacing padding={{ blockstart: "four", blockend: "two" }}>
                      <EGDSText align="right">
                        <div>{feeImage}</div>
                      </EGDSText>
                    </EGDSSpacing>
                  </div>
                </EGDSLayoutGridItem>
                <EGDSLayoutGridItem colSpan={textColSpan}>
                  <div>
                    <EGDSText align="left" size={400} weight="bold">
                      <EGDSSpacing padding={{ blockstart: "six", inlinestart: "three" }}>
                        <EGDSText align="left">
                          <div>{feeText}</div>
                        </EGDSText>
                      </EGDSSpacing>
                    </EGDSText>
                  </div>
                </EGDSLayoutGridItem>
                <EGDSLayoutGridItem colSpan={imageColSpan}>
                  <EGDSSpacing padding={{ blockstart: "six" }}>
                    <EGDSText align="center">
                      <EGDSPrimaryButton type="button" href={feeCardApplyNowLink}>
                        {applyNowText}{" "}
                      </EGDSPrimaryButton>
                    </EGDSText>
                  </EGDSSpacing>
                </EGDSLayoutGridItem>
                <EGDSLayoutGridItem colSpan={separatorColSpan}>
                  <div>
                    <EGDSSpacing margin={{ inlinestart: "twelve" }}>
                      <EGDSText align="center">
                        <div>{separator}</div>
                      </EGDSText>
                    </EGDSSpacing>
                  </div>
                </EGDSLayoutGridItem>
                <EGDSLayoutGridItem colSpan={imageColSpan}>
                  <div>
                    <EGDSSpacing padding={{ blockstart: "four", blockend: "two" }}>
                      <EGDSText align="right">
                        <div>{noFeeImage}</div>
                      </EGDSText>
                    </EGDSSpacing>
                  </div>
                </EGDSLayoutGridItem>
                <EGDSLayoutGridItem colSpan={textColSpan}>
                  <div>
                    <EGDSText align="left" size={400} weight="bold">
                      <EGDSSpacing padding={{ blockstart: "six", inlinestart: "three" }}>
                        <EGDSText align="left">
                          <div>{noFeeText}</div>
                        </EGDSText>
                      </EGDSSpacing>
                    </EGDSText>
                  </div>
                </EGDSLayoutGridItem>
                <EGDSLayoutGridItem colSpan={buttonColSpan}>
                  <EGDSSpacing padding={{ blockstart: "six" }}>
                    <EGDSText align="center">
                      <EGDSPrimaryButton type="button" href={noFeeCardApplyNowLink}>
                        {applyNowText}
                      </EGDSPrimaryButton>
                    </EGDSText>
                  </EGDSSpacing>
                </EGDSLayoutGridItem>
              </EGDSLayoutGrid>
            </div>
          </EGDSSticky>
        </section>
      </ViewExtraLarge>
      <ViewLarge>
        <section>
          <EGDSSticky hidden zIndex={1}>
            <div className="background-color-white withBorder uitk-button-old-raised">
              <EGDSLayoutGrid columns={gridColumns}>
                <EGDSLayoutGridItem colSpan={separatorColSpan}>
                  <div>
                    <EGDSSpacing padding={{ blockstart: "four", blockend: "two" }}>
                      <EGDSText align="right">
                        <div>{feeImage}</div>
                      </EGDSText>
                    </EGDSSpacing>
                  </div>
                </EGDSLayoutGridItem>
                <EGDSLayoutGridItem colSpan={buttonColSpan}>
                  <div>
                    <EGDSText align="left" size={400} weight="bold">
                      <EGDSSpacing padding={{ blockstart: "six", inlinestart: "three" }}>
                        <EGDSText align="left">
                          <div>{feeText}</div>
                        </EGDSText>
                      </EGDSSpacing>
                    </EGDSText>
                  </div>
                </EGDSLayoutGridItem>
                <EGDSLayoutGridItem colSpan={imageColSpan}>
                  <EGDSSpacing padding={{ blockstart: "six" }}>
                    <EGDSText align="center">
                      <EGDSPrimaryButton type="button" href={feeCardApplyNowLink}>
                        {applyNowText}
                      </EGDSPrimaryButton>
                    </EGDSText>
                  </EGDSSpacing>
                </EGDSLayoutGridItem>
                <EGDSLayoutGridItem colSpan={separatorColSpan}>
                  <EGDSSpacing padding={{ inlinestart: "four" }}>
                    <EGDSText align="center">
                      <div>{separator}</div>
                    </EGDSText>
                  </EGDSSpacing>
                </EGDSLayoutGridItem>
                <EGDSLayoutGridItem colSpan={separatorColSpan}>
                  <div>
                    <EGDSSpacing padding={{ blockstart: "four", blockend: "two" }}>
                      <EGDSText align="right">
                        <div>{noFeeImage}</div>
                      </EGDSText>
                    </EGDSSpacing>
                  </div>
                </EGDSLayoutGridItem>
                <EGDSLayoutGridItem colSpan={imageColSpan}>
                  <div>
                    <EGDSText align="left" size={400} weight="bold">
                      <EGDSSpacing padding={{ blockstart: "six", inlinestart: "three" }}>
                        <EGDSText align="left">
                          <div>{noFeeText}</div>
                        </EGDSText>
                      </EGDSSpacing>
                    </EGDSText>
                  </div>
                </EGDSLayoutGridItem>
                <EGDSLayoutGridItem colSpan={imageColSpan}>
                  <EGDSSpacing padding={{ blockstart: "six" }}>
                    <EGDSText align="center">
                      <EGDSPrimaryButton type="button" href={noFeeCardApplyNowLink}>
                        {applyNowText}
                      </EGDSPrimaryButton>
                    </EGDSText>
                  </EGDSSpacing>
                </EGDSLayoutGridItem>
              </EGDSLayoutGrid>
            </div>
          </EGDSSticky>
        </section>
      </ViewLarge>
    </Viewport>
  );
};

export default CitiDesktopStickyBar;
