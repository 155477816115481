import * as React from "react";
import EditorialBase from "components/flexComponents/Editorial/factory/EditorialBase";
import { EGDSCard } from "@egds/react-core/cards";
import { EGDSText, EGDSHeading, EGDSSubheading } from "@egds/react-core/text";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { EGDSLayoutGrid, EGDSLayoutGridItem } from "@egds/react-core/layout-grid";
import { ContentProps, EditorialFactoryProps } from "components/flexComponents/Editorial/typings";
import { DecorateEditorialHtml } from "src/components/flexComponents/Editorial/ContentUtilities";
import { EGDSPrimaryButton } from "@egds/react-core/button";
import { FlexClickTracker } from "components/utility/analytics/FlexClickTracker/FlexClickTracker";
import MediaDisplay from "../items/MediaDisplay";
import { useClickTracker } from "@shared-ui/clickstream-analytics-context";

export class HalfMediaEditorial extends EditorialBase {
  public constructor(editorial: EditorialFactoryProps) {
    super(editorial);
    this.linkName = "tab1carouseltextlink";
  }

  /**
   * An Editorial consists of HTML strings that we parse through with the best of
   * our abilities and display as is on the page. Mainly, we do not control the
   * content of an Editorial, we simply put them in the page.
   */
  public generate(props: ContentProps) {
    const rfrrid = this.getRfrrid();
    const { items, cardLinkLabel, cardLinkUrl, title: moduleTitle, subtitle: moduleSubtitle, boxType } = this.editorial;
    const { text, title, media, subtitle, id: itemId } = items[0];

    if (!media || media.length === 0) {
      return null;
    }

    const TrackedButton = FlexClickTracker(EGDSPrimaryButton);

    return (
      <EGDSSpacing padding={{ block: "three" }}>
        <div id={this.id} data-fm={this.fmId} data-fm-title-id={this.fmTitleId} className="HalfMediaEditorial">
          <EGDSCard border={boxType !== "no-border"}>
            <div>
              <EGDSLayoutGrid columns={{ small: 1, medium: 2 }} justifyContent="space-between">
                <EGDSLayoutGridItem>
                  <MediaDisplay id={itemId} media={media} title={title} />
                </EGDSLayoutGridItem>
                <EGDSLayoutGridItem>
                  <div>
                    <EGDSSpacing padding={{ block: "three", inline: "four" }}>
                      <div>
                        {moduleTitle && (
                          <EGDSSpacing margin={{ blockend: "two" }}>
                            <div>
                              <EGDSHeading tag="h2" size={5}>
                                {moduleTitle}
                              </EGDSHeading>
                              {moduleSubtitle && <EGDSSubheading>{moduleSubtitle}</EGDSSubheading>}
                            </div>
                          </EGDSSpacing>
                        )}
                        {title && (
                          <div>
                            <EGDSHeading tag={moduleTitle ? "h3" : "h2"} size={5}>
                              {title}
                            </EGDSHeading>
                            {subtitle && <EGDSSubheading>{subtitle}</EGDSSubheading>}
                          </div>
                        )}
                        <EGDSSpacing margin={{ blockstart: "two" }}>
                          <EGDSText size={300}>
                            <div onClick={this.trackRfrridClick(useClickTracker())}>
                              <DecorateEditorialHtml text={text} />
                            </div>
                          </EGDSText>
                        </EGDSSpacing>
                        {cardLinkUrl && cardLinkLabel && (
                          <EGDSSpacing margin={{ blockstart: "four" }}>
                            <TrackedButton
                              moduleName="HalfMediaEditorial"
                              href={cardLinkUrl}
                              rfrr={rfrrid}
                              aria-label={cardLinkLabel}
                            >
                              {cardLinkLabel}
                            </TrackedButton>
                          </EGDSSpacing>
                        )}
                      </div>
                    </EGDSSpacing>
                  </div>
                </EGDSLayoutGridItem>
              </EGDSLayoutGrid>
            </div>
          </EGDSCard>
        </div>
      </EGDSSpacing>
    );
  }
}

export default HalfMediaEditorial;
