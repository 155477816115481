import { EditorialFactoryProps } from "components/flexComponents/Editorial/typings";
import * as React from "react";
import { EGDSHeading, EGDSSubheading } from "@egds/react-core/text";
import { EGDSSpacing } from "@egds/react-core/spacing";
import RenderAbleContentItemExpando from "components/flexComponents/Editorial/factory/impl/items/RenderAbleContentItemExpando";
import EditorialBase from "components/flexComponents/Editorial/factory/EditorialBase";

export class DestinationDescriptionEditorial extends EditorialBase {
  /* istanbul ignore next */
  public constructor(editorial: EditorialFactoryProps) {
    super(editorial);
  }

  public generate() {
    const classNameAttr = `${this.getEditorialItemClassName()}`;

    const {
      title: editorialTitle,
      subtitle: editorialSubtitle,
      text: editorialBody,
      id: editorialId,
    } = this.editorial.items[0];

    return (
      <div className={classNameAttr} id={this.id} data-fm={this.fmId} data-fm-title-id={this.fmTitleId}>
        <div className="EditorialModuleTitle">
          {editorialTitle && (
            <EGDSSpacing margin={{ block: "one" }}>
              <EGDSHeading tag="h2" size={2}>
                {editorialTitle}
              </EGDSHeading>
            </EGDSSpacing>
          )}
          {editorialSubtitle && (
            <EGDSSpacing margin={{ block: "one" }}>
              <EGDSSubheading tag="h3">{editorialSubtitle}</EGDSSubheading>
            </EGDSSpacing>
          )}
        </div>
        {editorialBody && (
          <EGDSSpacing margin={{ block: "two" }}>
            <RenderAbleContentItemExpando id={editorialId} body={editorialBody} />
          </EGDSSpacing>
        )}
      </div>
    );
  }
}

export default DestinationDescriptionEditorial;
