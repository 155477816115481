import * as React from "react";
import { EGDSPrimaryButton } from "@egds/react-core/button";
import { DecorateEditorialHtml } from "src/components/flexComponents/Editorial/ContentUtilities";
import { EditorialModel } from "src/typings/microserviceModels/content-flex-module";

export const CitiLearnMoreButton = (props: { items: EditorialModel[]; cardComparisonClassName: string }) => {
  const { items, cardComparisonClassName } = props;
  if (items.length !== 1) {
    return null;
  }

  const onClickLearnMore = (): void => {
    const [cardComparisonElement] = document.getElementsByClassName(
      cardComparisonClassName
    ) as HTMLCollectionOf<HTMLElement>;
    window.scrollTo({
      behavior: "smooth",
      top: cardComparisonElement?.offsetTop,
    });
  };

  return (
    <>
      <EGDSPrimaryButton type="button" onClick={onClickLearnMore}>
        <DecorateEditorialHtml text={items[0].text} />
      </EGDSPrimaryButton>
    </>
  );
};

export default CitiLearnMoreButton;
