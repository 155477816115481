import * as React from "react";
import { useDialog } from "@shared-ui/dialog-context";
import { ContentProps } from "components/flexComponents/Editorial/typings";
import { EGDSIcon, EGDSIconSize } from "@egds/react-core/icons";
import { EGDSCard, EGDSCardContentSection, EGDSCardActionArea } from "@egds/react-core/cards";
import { EGDSTertiaryButton, EGDSButtonSize } from "@egds/react-core/button";
import { EditorialModel } from "typings/microserviceModels/content-flex-module";
import { DecorateEditorialHtml } from "src/components/flexComponents/Editorial/ContentUtilities";
import { useLocalization } from "@shared-ui/localization-context";
import { EGDSHeading, EGDSText } from "@egds/react-core/text";
import { EGDSLayoutGrid, EGDSLayoutGridItem } from "@egds/react-core/layout-grid";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { EGDSSheetTransition } from "@egds/react-core/sheet";

export const EditorialInfoView = ({ items, props }: { items: EditorialModel[]; props: ContentProps }) => {
  const moreInfoRef = React.createRef<HTMLButtonElement>();
  const [isDialogOpen, dialogActions, EditorialInfoDialogComponent] = useDialog("more-info");

  /* istanbul ignore next */
  const closeDialog = () => {
    dialogActions.closeDialog();

    if (moreInfoRef && moreInfoRef.current && typeof moreInfoRef.current.focus === "function") {
      moreInfoRef.current.focus();
    }
  };

  const { formatText } = useLocalization();

  return (
    <div data-fm-content={items[0].id}>
      {items[0].title && (
        <EGDSHeading tag="h2" size={4}>
          {items[0].title}
        </EGDSHeading>
      )}
      {items[0].text && (
        <EGDSSpacing padding={{ blockstart: "two" }}>
          <div className="infoMessage" onClick={dialogActions.openDialog as any}>
            <EGDSText size={300}>
              <DecorateEditorialHtml text={items[0].text} />
              <EGDSIcon name="info_outline" size={EGDSIconSize.SMALL} />
            </EGDSText>
          </div>
        </EGDSSpacing>
      )}
      <EGDSSheetTransition isVisible={isDialogOpen}>
        <EditorialInfoDialogComponent>
          <InfoDialog
            infoText={items[1].text}
            closeDialog={closeDialog}
            buttonText={formatText("editorial.moreInfo.dismissButton.label")}
          />
        </EditorialInfoDialogComponent>
      </EGDSSheetTransition>
    </div>
  );
};

const InfoDialog = ({
  infoText,
  closeDialog,
  buttonText,
}: {
  infoText?: string;
  closeDialog: () => void;
  buttonText: string;
}) => {
  const { useRef, useEffect } = React;
  const dialogRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    if (dialogRef && dialogRef.current) {
      dialogRef.current.focus();
    }
  }, []);

  return (
    <EGDSCard className="editorialInfoDialog">
      <EGDSCardContentSection>
        <EGDSLayoutGrid columns={1}>
          <EGDSLayoutGridItem>
            <EGDSText size={300}>{infoText && <DecorateEditorialHtml text={infoText} />}</EGDSText>
          </EGDSLayoutGridItem>
        </EGDSLayoutGrid>
        <EGDSCardActionArea>
          <EGDSTertiaryButton onClick={closeDialog} size={EGDSButtonSize.LARGE} isFullWidth domRef={dialogRef}>
            {buttonText}
          </EGDSTertiaryButton>
        </EGDSCardActionArea>
      </EGDSCardContentSection>
    </EGDSCard>
  );
};
