import * as React from "react";
import {
  EGDSLayoutGrid,
  EGDSLayoutGridItem,
  EGDSLayoutGridColumnRow,
  EGDSLayoutGridSpan,
} from "@egds/react-core/layout-grid";
import { DecorateEditorialHtml } from "src/components/flexComponents/Editorial/ContentUtilities";
import { EGDSText } from "@egds/react-core/text";
import { EditorialModel } from "src/typings/microserviceModels/content-flex-module";
import { EGDSSpacing } from "@egds/react-core/spacing";

interface IconsProps {
  icon: React.ReactNode;
  title: React.ReactNode;
  description: React.ReactNode;
}

export const Icons: React.FC<IconsProps> = ({ icon, description, title }) => (
  <EGDSSpacing padding={{ inline: "six" }}>
    <div>
      <EGDSSpacing
        padding={{
          extra_large: { blockstart: "twelve" },
          large: { blockstart: "six" },
          medium: { blockstart: "six" },
          small: { blockstart: "six" },
        }}
      >
        <EGDSText align="center">
          <div>{icon}</div>
        </EGDSText>
      </EGDSSpacing>
      <EGDSSpacing padding={{ blockstart: "four" }}>
        <EGDSText align="center" size={500} weight="bold">
          <div>{title}</div>
        </EGDSText>
      </EGDSSpacing>
      <EGDSSpacing padding={{ blockstart: "one" }}>
        <EGDSText align="center" size={300}>
          <div>{description}</div>
        </EGDSText>
      </EGDSSpacing>
    </div>
  </EGDSSpacing>
);

export const IconSectionEditorial = (props: { items: EditorialModel[] }) => {
  const { items } = props;

  if (items.length !== 10) {
    return null;
  }

  const [
    iconSectionTitle,
    moreRewardsIcon,
    moreRewardsTitle,
    moreRewardsDescription,
    ultimateFlexibilityIcon,
    ultimateFlexibilityTitle,
    ultimateFlexibilityDescription,
    lessHassleIcon,
    lessHassleTitle,
    lessHassleDescription,
  ] = items.map((item) => <DecorateEditorialHtml key={item.id} text={item.text} />);

  const IconColSpan = {
    extra_large: 4 as EGDSLayoutGridSpan,
    large: 4 as EGDSLayoutGridSpan,
    medium: 4 as EGDSLayoutGridSpan,
    small: 1 as EGDSLayoutGridSpan,
  };

  const gridColumns = {
    extra_large: 12 as EGDSLayoutGridColumnRow,
    large: 12 as EGDSLayoutGridColumnRow,
    medium: 12 as EGDSLayoutGridColumnRow,
    small: 1 as EGDSLayoutGridColumnRow,
  };

  return (
    <EGDSSpacing padding={{ block: "twelve" }}>
      <div className="icon-section">
        <EGDSText align="center" size={600} weight="bold" spacing="four">
          <div>{iconSectionTitle}</div>
        </EGDSText>

        <EGDSText spacing="six" />

        <EGDSLayoutGrid columns={gridColumns}>
          <EGDSLayoutGridItem colSpan={IconColSpan}>
            <EGDSLayoutGrid justifyItems="center">
              <EGDSLayoutGridItem>
                <Icons icon={moreRewardsIcon} title={moreRewardsTitle} description={moreRewardsDescription} />
              </EGDSLayoutGridItem>
            </EGDSLayoutGrid>
          </EGDSLayoutGridItem>

          <EGDSLayoutGridItem colSpan={IconColSpan}>
            <EGDSLayoutGrid justifyItems="center">
              <EGDSLayoutGridItem>
                <Icons
                  icon={ultimateFlexibilityIcon}
                  title={ultimateFlexibilityTitle}
                  description={ultimateFlexibilityDescription}
                />
              </EGDSLayoutGridItem>
            </EGDSLayoutGrid>
          </EGDSLayoutGridItem>

          <EGDSLayoutGridItem colSpan={IconColSpan}>
            <EGDSLayoutGrid justifyItems="center">
              <EGDSLayoutGridItem>
                <Icons icon={lessHassleIcon} title={lessHassleTitle} description={lessHassleDescription} />
              </EGDSLayoutGridItem>
            </EGDSLayoutGrid>
          </EGDSLayoutGridItem>
        </EGDSLayoutGrid>
      </div>
    </EGDSSpacing>
  );
};

export default IconSectionEditorial;
