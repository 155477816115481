import * as React from "react";
import { DecorateEditorialHtml } from "src/components/flexComponents/Editorial/ContentUtilities";
import { EGDSText } from "@egds/react-core/text";
import { EditorialModel } from "src/typings/microserviceModels/content-flex-module";
import { EGDSSpacing } from "@egds/react-core/spacing";

const CONTENT_ID_COUNT = 3;
export const CardComparisonSectionEditorial = (props: { items: EditorialModel[]; cardComparisonClassName: string }) => {
  const { items, cardComparisonClassName } = props;

  if (items.length !== CONTENT_ID_COUNT) {
    return null;
  }

  const [cardTitle, cardSubTitle, description] = React.useMemo(
    () => items.map((item) => <DecorateEditorialHtml key={item.id} text={item.text} />),
    []
  );

  return (
    <div className={cardComparisonClassName}>
      <EGDSText align="center" size={700} weight="bold">
        <EGDSSpacing padding={{ blockstart: "twelve" }}>
          <div>{cardTitle}</div>
        </EGDSSpacing>
      </EGDSText>
      <EGDSText align="center" size={400} weight="bold">
        <EGDSSpacing padding={{ blockstart: "one" }}>
          <div>{cardSubTitle}</div>
        </EGDSSpacing>
      </EGDSText>
      <EGDSText align="center" size={200}>
        <EGDSSpacing padding={{ blockstart: "two" }}>
          <div>{description}</div>
        </EGDSSpacing>
      </EGDSText>
    </div>
  );
};

export default CardComparisonSectionEditorial;
