import * as React from "react";
import { EGDSLayoutGrid, EGDSLayoutGridItem } from "@egds/react-core/layout-grid";
import { DecorateEditorialHtml } from "src/components/flexComponents/Editorial/ContentUtilities";
import { EGDSText } from "@egds/react-core/text";
import { EditorialModel } from "src/typings/microserviceModels/content-flex-module";
import { EGDSSpacing } from "@egds/react-core/spacing";

const CONTENT_ID_COUNT = 6;
export const CardComparisonFeatureSectionEditorial = (props: { items: EditorialModel[] }) => {
  const { items } = props;

  if (items.length !== CONTENT_ID_COUNT) {
    return null;
  }

  const [
    cardStatusFeatureOneIcon,
    cardStatusFeatureOneDescription,
    cardStatusFeatureTwoIcon,
    cardStatusFeatureTwoDescription,
    cardStatusFeatureThreeIcon,
    cardStatusFeatureThreeDescription,
  ] = React.useMemo(() => items.map((item) => <DecorateEditorialHtml key={item.id} text={item.text} />), []);

  return (
    <div>
      <EGDSLayoutGrid columns={12} space="six">
        <EGDSSpacing padding={{ blockend: "two" }}>
          <EGDSLayoutGridItem colSpan={4}>
            <div>
              <EGDSText align="center">
                <div>{cardStatusFeatureOneIcon}</div>
              </EGDSText>
              <EGDSSpacing padding={{ blockstart: "one", blockend: "six" }}>
                <EGDSText align="center" size={200} weight="medium">
                  <div>{cardStatusFeatureOneDescription}</div>
                </EGDSText>
              </EGDSSpacing>
            </div>
          </EGDSLayoutGridItem>
        </EGDSSpacing>

        <EGDSLayoutGridItem colSpan={4}>
          <div>
            <EGDSText align="center">
              <div>{cardStatusFeatureTwoIcon}</div>
            </EGDSText>
            <EGDSSpacing padding={{ blockstart: "one", blockend: "half" }}>
              <EGDSText align="center" size={200} weight="medium">
                <div>{cardStatusFeatureTwoDescription}</div>
              </EGDSText>
            </EGDSSpacing>
          </div>
        </EGDSLayoutGridItem>

        <EGDSSpacing padding={{ blockend: "two" }}>
          <EGDSLayoutGridItem colSpan={4}>
            <div>
              <EGDSText align="center">
                <div>{cardStatusFeatureThreeIcon}</div>
              </EGDSText>
              <EGDSSpacing padding={{ blockstart: "one", blockend: "six" }}>
                <EGDSText align="center" size={200} weight="medium">
                  <div>{cardStatusFeatureThreeDescription}</div>
                </EGDSText>
              </EGDSSpacing>
            </div>
          </EGDSLayoutGridItem>
        </EGDSSpacing>
      </EGDSLayoutGrid>
    </div>
  );
};

export default CardComparisonFeatureSectionEditorial;
