import * as React from "react";
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { EGDSText } from "@egds/react-core/text";
import { EGDSMark } from "@egds/react-core/mark";

import EditorialBase from "../../EditorialBase";
import { ContentProps, EditorialFactoryProps } from "../../../typings";
import { DecorateEditorialHtml } from "src/components/flexComponents/Editorial/ContentUtilities";

export class ConfidenceMessageImageEditorial extends EditorialBase {
  public constructor(editorial: EditorialFactoryProps) {
    super(editorial);
  }

  public generate(props: ContentProps) {
    const { items } = this.editorial;
    const { media, text } = items[0];

    if (!media || media.length === 0 || !text) {
      return null;
    }

    return (
      <div
        id={this.id}
        data-fm={this.fmId}
        data-fm-title-id={this.fmTitleId}
        className="ConfidenceMessageImageEditorial"
        data-testid="confidence-message-image-editorial"
      >
        <EGDSSpacing margin={{ block: "one" }}>
          <EGDSLayoutFlex space="three" direction="row">
            <EGDSLayoutFlexItem>
              <EGDSMark url={media[0].mediaUrl} description={media[0].mediaAlt} size={12} lazyLoading="lazy" />
            </EGDSLayoutFlexItem>
            <EGDSLayoutFlexItem alignSelf="center" shrink={1}>
              <EGDSText size={300}>
                <DecorateEditorialHtml text={text} />
              </EGDSText>
            </EGDSLayoutFlexItem>
          </EGDSLayoutFlex>
        </EGDSSpacing>
      </div>
    );
  }
}

export default ConfidenceMessageImageEditorial;
