import * as React from "react";
import { EGDSLayoutGrid, EGDSLayoutGridItem, EGDSLayoutConditionalGridSpan } from "@egds/react-core/layout-grid";
import { EGDSText } from "@egds/react-core/text";
import { EGDSFigure, EGDSImage } from "@egds/react-core/images";
import { EGDSCard, EGDSCardContentSection } from "@egds/react-core/cards";
import { DecorateEditorialHtml } from "src/components/flexComponents/Editorial/ContentUtilities";
import { EditorialFlexModule } from "src/typings/microserviceModels/content-flex-module";
import { ExtendedContextStore } from "src/typings/flexFramework/FlexDefinitions";
import { EGDSSpacing } from "@egds/react-core/spacing";

export const CitiRewardsWithoutCompSectionEditorial = (props: {
  model: EditorialFlexModule;
  context: ExtendedContextStore;
}) => {
  const {
    model: { items: textElements },
    context: { deviceInformation },
  } = props;
  const items = (textElements && textElements.filter((textElement) => textElement)) || [];
  const { tablet: isTablet, mobile: isMobile } = deviceInformation || {};
  const imageColSpan: EGDSLayoutConditionalGridSpan = { small: 6, medium: 5, large: 5, extra_large: 5 };
  const iconColSpan: EGDSLayoutConditionalGridSpan = { small: 2, medium: 3, large: 2, extra_large: 2 };
  const imagegridColumns = {
    extra_large: 12,
    large: 12,
    medium: 12,
    small: 1,
  } as const;
  const textgridColumns = {
    extra_large: 12,
    large: 12,
    medium: 12,
    small: 6,
  } as const;

  if (items.length !== 8) {
    return null;
  }

  const [headingText, subHeadingText, flightIcon, hotelIcon, carIcon, activityIcon, vacationIcon] = items
    .slice(1)
    .map((item) => <DecorateEditorialHtml key={item.id} text={item.text} />);
  const altText = items[0].text;
  const media = items[0].media;
  let mediaUrl = media && media[1].mediaUrl;
  if (isMobile) {
    mediaUrl = media && media[0].mediaUrl;
  } else if (isTablet) {
    mediaUrl = media && media[2].mediaUrl;
  }

  return (
    <EGDSSpacing padding={{ inline: "twelve", block: "twelve" }}>
      <EGDSCard border>
        <EGDSLayoutGrid columns={imagegridColumns}>
          <EGDSLayoutGridItem colSpan={imageColSpan}>
            <EGDSFigure ratio={EGDSFigure.AspectRatio.R16_9}>
              <EGDSImage src={mediaUrl} alt={altText} />
            </EGDSFigure>
          </EGDSLayoutGridItem>

          <EGDSLayoutGridItem colSpan={6}>
            <div>
              <EGDSCardContentSection>
                <EGDSText align="left" size={700} weight="bold">
                  <EGDSSpacing
                    padding={{
                      small: { inline: "two", blockstart: "three", blockend: "two" },
                      medium: { inline: "six", blockstart: "twelve" },
                    }}
                  >
                    <div>{headingText}</div>
                  </EGDSSpacing>
                </EGDSText>

                <EGDSText align="left" size={300} weight="regular">
                  <EGDSSpacing
                    padding={{ small: { inline: "two" }, medium: { inline: "six" }, large: { blockend: "six" } }}
                  >
                    <div>{subHeadingText}</div>
                  </EGDSSpacing>
                </EGDSText>
              </EGDSCardContentSection>

              <EGDSCardContentSection>
                <EGDSSpacing
                  padding={{
                    small: { inlinestart: "one", inlineend: "four", block: "six" },
                    medium: { inlinestart: "twelve", blockend: "twelve", blockstart: "six" },
                    large: { blockstart: "unset" },
                  }}
                >
                  <EGDSLayoutGrid rows={1} columns={textgridColumns} space="six">
                    <EGDSLayoutGridItem colSpan={2}>
                      <div>
                        <EGDSText align="center" size={300} weight="regular">
                          <div>{flightIcon}</div>
                        </EGDSText>
                      </div>
                    </EGDSLayoutGridItem>

                    <EGDSLayoutGridItem colSpan={2}>
                      <EGDSSpacing padding={{ medium: { inlinestart: "two" } }}>
                        <div>
                          <EGDSText align="center" size={300} weight="regular">
                            <div>{hotelIcon}</div>
                          </EGDSText>
                        </div>
                      </EGDSSpacing>
                    </EGDSLayoutGridItem>

                    <EGDSLayoutGridItem colSpan={2}>
                      <EGDSSpacing padding={{ medium: { inlinestart: "two" } }}>
                        <div>
                          <EGDSText align="center" size={300} weight="regular">
                            <div>{carIcon}</div>
                          </EGDSText>
                        </div>
                      </EGDSSpacing>
                    </EGDSLayoutGridItem>

                    <EGDSLayoutGridItem colSpan={iconColSpan}>
                      <EGDSSpacing padding={{ medium: { inlinestart: "two" } }}>
                        <div>
                          <EGDSText align="center" size={300} weight="regular">
                            <div>{activityIcon}</div>
                          </EGDSText>
                        </div>
                      </EGDSSpacing>
                    </EGDSLayoutGridItem>

                    <EGDSLayoutGridItem colSpan={iconColSpan}>
                      <EGDSSpacing padding={{ medium: { inlinestart: "two" } }}>
                        <div>
                          <EGDSText align="center" size={300} weight="regular">
                            <div>{vacationIcon}</div>
                          </EGDSText>
                        </div>
                      </EGDSSpacing>
                    </EGDSLayoutGridItem>
                  </EGDSLayoutGrid>
                </EGDSSpacing>
              </EGDSCardContentSection>
            </div>
          </EGDSLayoutGridItem>
        </EGDSLayoutGrid>
      </EGDSCard>
    </EGDSSpacing>
  );
};
export default CitiRewardsWithoutCompSectionEditorial;
