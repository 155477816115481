import * as React from "react";
import LazyLoad from "react-lazyload";
import EditorialBase from "components/flexComponents/Editorial/factory/EditorialBase";
import { EGDSCard, EGDSCardPlaceholder } from "@egds/react-core/cards";
import { EGDSText, EGDSHeading, EGDSSubheading } from "@egds/react-core/text";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { EGDSLayoutGrid, EGDSLayoutGridItem } from "@egds/react-core/layout-grid";
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { EditorialFactoryProps, ContentProps } from "components/flexComponents/Editorial/typings";
import { Media } from "src/typings/microserviceModels/content-flex-module";
import { DecorateEditorialHtml } from "src/components/flexComponents/Editorial/ContentUtilities";
import { EGDSFigure, EGDSFigureAspectRatioType } from "@egds/react-core/images";
import { EGDSScrimType, EGDSScrim } from "@egds/react-core/scrim";
import { EGDSAvatar } from "@egds/react-core/avatar";
import { BlossomImage } from "src/components/shared/BlossomImage/BlossomImage";
import { TrackedLink } from "components/utility/analytics/TrackedLink";
import { useClickTracker } from "@shared-ui/clickstream-analytics-context";

const ImageDisplay = ({ image, rfrrid }: { image: Media; rfrrid?: string }) => {
  return (
    <LazyLoad offset={500} height="100%" once placeholder={<EGDSCardPlaceholder />}>
      <EGDSCard>
        <EGDSFigure ratio={EGDSFigureAspectRatioType.R1_1}>
          <BlossomImage alt={image.mediaAlt} src={image.mediaUrl} imageRatio="1-1" />
          {image.clickUrl && (
            <TrackedLink
              className="uitk-card-link"
              rfrr={rfrrid}
              href={image.clickUrl}
              target="_blank"
              rel="noopener noreferrer"
              moduleName="SocialMediaGridEditorial"
            />
          )}
          {image.mediaCaption && (
            <EGDSScrim type={EGDSScrimType.BOTTOM}>
              <EGDSText>{image.mediaCaption}</EGDSText>
            </EGDSScrim>
          )}
        </EGDSFigure>
      </EGDSCard>
    </LazyLoad>
  );
};

export class SocialMediaGridEditorial extends EditorialBase {
  public constructor(editorial: EditorialFactoryProps) {
    super(editorial);
  }

  /**
   * An Editorial consists of HTML strings that we parse through with the best of
   * our abilities and display as is on the page. Mainly, we do not control the
   * content of an Editorial, we simply put them in the page.
   */

  public generate(props: ContentProps) {
    const { title: moduleTitle } = this.editorial;
    const { media, title, subtitle, text } = this.editorial.items[0];

    const socialMediaImages = media?.filter((image) => {
      return image.imageType === "General Image";
    });

    const socialMediaAvatar = media?.filter((image) => {
      return image.imageType === "Logo";
    })[0];

    if (!media || media.length === 0) {
      return null;
    }

    return (
      <EGDSSpacing padding={{ block: "three" }}>
        <div id={this.id} data-fm={this.fmId} data-fm-title-id={this.fmTitleId} className="SocialMediaGridEditorial">
          <>
            {moduleTitle && (
              <EGDSSpacing margin={{ block: "two", inline: "three" }}>
                <div>
                  <EGDSHeading tag="h2" size={5}>
                    {moduleTitle}
                  </EGDSHeading>
                </div>
              </EGDSSpacing>
            )}
            <EGDSLayoutFlex alignContent="start" alignItems="center">
              <>
                {socialMediaAvatar && (
                  <EGDSLayoutFlexItem maxWidth="full_width">
                    <a href={socialMediaAvatar.clickUrl} target="_blank" rel="noopener noreferrer">
                      <EGDSAvatar
                        image={{ alt: socialMediaAvatar.mediaAlt, src: socialMediaAvatar.mediaUrl }}
                        border
                        size="large"
                      />
                    </a>
                  </EGDSLayoutFlexItem>
                )}
                {title && (
                  <EGDSLayoutFlexItem>
                    <EGDSSpacing margin={{ inline: "one" }} padding={{ block: "two" }}>
                      <div>
                        <EGDSHeading tag="h3" size={5}>
                          {title}
                        </EGDSHeading>
                        {subtitle && <EGDSSubheading>{subtitle}</EGDSSubheading>}
                      </div>
                    </EGDSSpacing>
                  </EGDSLayoutFlexItem>
                )}
              </>
            </EGDSLayoutFlex>
          </>
          {text && (
            <EGDSSpacing margin={{ blockstart: "two", blockend: "two" }}>
              <EGDSText size={300}>
                <div onClick={this.trackRfrridClick(useClickTracker())}>
                  <DecorateEditorialHtml text={text} />
                </div>
              </EGDSText>
            </EGDSSpacing>
          )}

          <EGDSLayoutGrid columns={{ small: 3, medium: 6 }} justifyContent="space-around" space="two">
            {socialMediaImages &&
              socialMediaImages.length >= 6 &&
              socialMediaImages.length < 12 &&
              socialMediaImages.slice(0, 6).map((item, index) => {
                const rfrrid = `${this.pageName}.${this.moduleName}.${this.linkName}${index + 1}.${this.sectionName}`;
                return (
                  <EGDSLayoutGridItem key={this.keyHelper.next()}>
                    <ImageDisplay image={item} rfrrid={rfrrid} />;
                  </EGDSLayoutGridItem>
                );
              })}
            {socialMediaImages &&
              socialMediaImages.length >= 12 &&
              media.slice(0, 12).map((item, index) => {
                const rfrrid = `${this.pageName}.${this.moduleName}.${this.linkName}${index + 1}.${this.sectionName}`;
                return (
                  <EGDSLayoutGridItem key={this.keyHelper.next()}>
                    <ImageDisplay image={item} rfrrid={rfrrid} />;
                  </EGDSLayoutGridItem>
                );
              })}
          </EGDSLayoutGrid>
        </div>
      </EGDSSpacing>
    );
  }
}

export default SocialMediaGridEditorial;
