import * as React from "react";
import { EGDSLayoutFlex } from "@egds/react-core/layout-flex";
import { EGDSIcon, EGDSIconSize } from "@egds/react-core/icons";
import { EGDSCard, EGDSCardContentSection } from "@egds/react-core/cards";
import { DecorateEditorialHtml } from "src/components/flexComponents/Editorial/ContentUtilities";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { EGDSText } from "@egds/react-core/text";

/**
 * Editorial item for a items with contentPurpose: Confidence message (and Highlights apparently)
 */
export const ConfidenceEditorialItem = ({
  id,
  text,
  uitkIconName,
  iconSize,
  boxType,
}: {
  id: string;
  text?: string;
  uitkIconName?: string;
  iconSize?: string;
  boxType?: string;
}) => {
  const confidenceEGDSIconName = uitkIconName || "done";
  const useEGDSCard = boxType === "blossom-white";

  const mainContent = (
    <EGDSSpacing margin={{ blockstart: "two" }}>
      <EGDSLayoutFlex
        alignItems="center"
        data-testid="confidenceMsg"
        data-fm-content={id}
        className="confidenceMessageItem"
      >
        <EGDSIcon
          name={confidenceEGDSIconName}
          size={iconSize === "large" ? EGDSIconSize.LARGE : EGDSIconSize.MEDIUM}
          data-testid="confidenceIcon"
          className={confidenceEGDSIconName}
        />
        <EGDSSpacing margin={{ inlinestart: "two" }}>
          <EGDSText size={300}>
            <DecorateEditorialHtml text={text} />
          </EGDSText>
        </EGDSSpacing>
      </EGDSLayoutFlex>
    </EGDSSpacing>
  );

  return useEGDSCard ? (
    <EGDSCard>
      <EGDSCardContentSection>{mainContent}</EGDSCardContentSection>
    </EGDSCard>
  ) : (
    mainContent
  );
};
