import * as React from "react";
import {
  EGDSLayoutGrid,
  EGDSLayoutGridItem,
  EGDSLayoutGridColumnRow,
  EGDSLayoutConditionalGridSpan,
} from "@egds/react-core/layout-grid";
import { EGDSText } from "@egds/react-core/text";
import { EGDSCard } from "@egds/react-core/cards";
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { DecorateEditorialHtml } from "src/components/flexComponents/Editorial/ContentUtilities";
import { EditorialModel } from "src/typings/microserviceModels/content-flex-module";
import { EGDSSpacing } from "@egds/react-core/spacing";

export const CitiCalculatorContainer = (props: { items: EditorialModel[] }) => {
  const { items } = props;
  const containerColSpan: EGDSLayoutConditionalGridSpan = { small: 2, medium: 3, large: 3, extra_large: 3 };
  const hotelColSpan: EGDSLayoutConditionalGridSpan = { small: 1, medium: 3, large: 3, extra_large: 3 };
  const compareColSpan: EGDSLayoutConditionalGridSpan = { small: 1, medium: 1, large: 2, extra_large: 2 };
  const costColSpan: EGDSLayoutConditionalGridSpan = { small: 3, medium: 4, large: 3, extra_large: 3 };
  const containerGridColumns = {
    extra_large: 12 as EGDSLayoutGridColumnRow,
    large: 12 as EGDSLayoutGridColumnRow,
    medium: 12 as EGDSLayoutGridColumnRow,
    small: 8 as EGDSLayoutGridColumnRow,
  };

  if (items.length !== 6) {
    return null;
  }

  const [flightText, hotelText, totalCostText, totalCostAmount, add, compare] = items.map((item) => (
    <DecorateEditorialHtml key={item.id} text={item.text} />
  ));

  return (
    <EGDSSpacing padding={{ blockend: "six" }}>
      <EGDSLayoutFlex space="two" justifyContent="center">
        <EGDSLayoutFlexItem grow={1} minWidth="twenty_four" maxWidth="one_hundred_twenty">
          <EGDSCard border>
            <EGDSSpacing padding={{ inlineend: "six" }}>
              <EGDSLayoutGrid columns={containerGridColumns} space="six" alignContent="center">
                <EGDSLayoutGridItem colSpan={containerColSpan}>
                  <EGDSSpacing padding={{ blockstart: "six", inlinestart: "six" }}>
                    <EGDSLayoutGrid columns={1}>
                      <EGDSText align="center" size={300}>
                        <div>{flightText}</div>
                      </EGDSText>
                    </EGDSLayoutGrid>
                  </EGDSSpacing>
                </EGDSLayoutGridItem>

                <EGDSLayoutGridItem colSpan={1}>
                  <EGDSSpacing padding={{ blockstart: "six", inlinestart: "two" }}>
                    <EGDSText align="center" size={800}>
                      <div>{add}</div>
                    </EGDSText>
                  </EGDSSpacing>
                </EGDSLayoutGridItem>

                <EGDSLayoutGridItem colSpan={hotelColSpan}>
                  <EGDSSpacing
                    padding={{
                      blockstart: "six",
                      medium: { inlinestart: "six" },
                      small: { inlineend: "two" },
                    }}
                  >
                    <EGDSLayoutGrid columns={1}>
                      <EGDSText align="center" size={300}>
                        <div>{hotelText}</div>
                      </EGDSText>
                    </EGDSLayoutGrid>
                  </EGDSSpacing>
                </EGDSLayoutGridItem>

                <EGDSLayoutGridItem colSpan={compareColSpan}>
                  <EGDSSpacing padding={{ block: "six", small: { inlinestart: "two" } }}>
                    <EGDSText align="center" size={800}>
                      <div>{compare}</div>
                    </EGDSText>
                  </EGDSSpacing>
                </EGDSLayoutGridItem>

                <EGDSLayoutGridItem colSpan={costColSpan}>
                  <EGDSSpacing padding={{ block: "six" }}>
                    <EGDSLayoutGrid columns={1}>
                      <EGDSText align="center" size={300}>
                        <div>{totalCostText}</div>
                      </EGDSText>
                      <EGDSText align="center" size={600} weight="bold">
                        <div>{totalCostAmount}</div>
                      </EGDSText>
                    </EGDSLayoutGrid>
                  </EGDSSpacing>
                </EGDSLayoutGridItem>
              </EGDSLayoutGrid>
            </EGDSSpacing>
          </EGDSCard>
        </EGDSLayoutFlexItem>
      </EGDSLayoutFlex>
    </EGDSSpacing>
  );
};
export default CitiCalculatorContainer;
