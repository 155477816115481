import * as React from "react";
import { EGDSCard, EGDSCardContentSection } from "@egds/react-core/cards";
import { EGDSHeading, EGDSText, EGDSSubheading } from "@egds/react-core/text";
import { ContentProps, EditorialFactoryProps } from "../../../typings";
import EditorialBase from "../../EditorialBase";
import IsomorphicCarousel from "src/components/shared/IsomorphicCarousel/IsomorphicCarousel";
import { DecorateEditorialHtml } from "src/components/flexComponents/Editorial/ContentUtilities";
import { ItemKeyHelper } from "src/components/utility/ItemKeyHelper";
import { EGDSFigure, EGDSFigureAspectRatioType } from "@egds/react-core/images";
import { useLocalization } from "@shared-ui/localization-context";
import { BlossomImage } from "src/components/shared/BlossomImage/BlossomImage";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { EGDSLayoutGrid, EGDSLayoutGridItem } from "@egds/react-core/layout-grid";

export class CarouselEditorial extends EditorialBase {
  constructor(editorial: EditorialFactoryProps) {
    super(editorial);
  }

  public generate(props: ContentProps) {
    const {
      templateComponent: {
        metadata: { id },
      },
    } = props;
    const itemsVisible = {
      lg: 3,
      md: 2,
      sm: 1,
    };
    const { formatText } = useLocalization();
    const keyHelper = new ItemKeyHelper(id);

    return (
      <div className="editorial-carousel" data-testid="carousel-editorial" id={id}>
        {this.editorial.title && (
          <EGDSSpacing margin={{ block: "three", inline: "three" }}>
            <EGDSHeading tag="h2" size={3}>
              {this.editorial.title}
            </EGDSHeading>
          </EGDSSpacing>
        )}
        <EGDSSpacing margin={{ blockstart: "three", blockend: "unset", inlinestart: "three" }}>
          <IsomorphicCarousel
            itemsVisible={itemsVisible}
            buttonText={{
              nextButton: formatText("carousel.item.next"),
              prevButton: formatText("carousel.item.prev"),
            }}
            pageBy={1}
            peek
            wrapItems={false}
          >
            {this.editorial.items.map((editorialItem) => (
              <EGDSCard className="blossom-plain" key={keyHelper.next()}>
                <div data-testid="editorial-carousel-content">
                  <EGDSLayoutGrid columns={1}>
                    {editorialItem.media && (
                      <EGDSLayoutGridItem>
                        <EGDSFigure ratio={EGDSFigureAspectRatioType.R16_9} className="all-image-fit-cover">
                          <BlossomImage
                            src={editorialItem.media[0].mediaUrl}
                            alt={editorialItem.media[0].mediaAlt || ""}
                          />
                        </EGDSFigure>
                      </EGDSLayoutGridItem>
                    )}
                    <EGDSLayoutGridItem>
                      <EGDSCardContentSection>
                        <div data-fm-content={editorialItem.id} id={editorialItem.id}>
                          {editorialItem.title && (
                            <EGDSHeading tag="h3" size={6}>
                              {editorialItem.title}
                            </EGDSHeading>
                          )}
                          {editorialItem.subtitle && <EGDSSubheading>{editorialItem.subtitle}</EGDSSubheading>}
                          {editorialItem.text && (
                            <EGDSText>
                              <EGDSSpacing padding={{ blockstart: "two" }}>
                                <div>
                                  <DecorateEditorialHtml text={editorialItem.text} />
                                </div>
                              </EGDSSpacing>
                            </EGDSText>
                          )}
                        </div>
                      </EGDSCardContentSection>
                    </EGDSLayoutGridItem>
                  </EGDSLayoutGrid>
                </div>
              </EGDSCard>
            ))}
          </IsomorphicCarousel>
        </EGDSSpacing>
      </div>
    );
  }
}
