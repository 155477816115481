import { EGDSCardContentSection, EGDSCard } from "@egds/react-core/cards";
import { EGDSHeading, EGDSSubheading, EGDSText } from "@egds/react-core/text";
import { EGDSFigure } from "@egds/react-core/images";
import { EGDSIcon } from "@egds/react-core/icons";
import * as React from "react";
import { ConditionalLazyLoad, DecorateEditorialHtml } from "components/flexComponents/Editorial/ContentUtilities";
import { uitkFigureAspectRatio } from "../editorials/EditorialWithLink";
import { BlossomImage } from "src/components/shared/BlossomImage/BlossomImage";
import { useClickTracker } from "@shared-ui/clickstream-analytics-context";
import { useRenderableContext } from "./DefaultEditorial";
import { EGDSSpacing, EGDSSpacingProps } from "@egds/react-core/spacing";
import { EGDSLayoutGrid, EGDSLayoutGridItem } from "@egds/react-core/layout-grid";
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";

export interface RenderableContentCardProps {
  shouldRenderBorder?: boolean;
  isExpandoEnabled?: boolean | "";
  toggleStatus: boolean;
  flipToggleStatus: () => void;
  displayEditorialClassNames: string;
}

export const RenderableContentCard = ({
  shouldRenderBorder,
  isExpandoEnabled,
  toggleStatus,
  flipToggleStatus,
  displayEditorialClassNames,
}: RenderableContentCardProps) => {
  const {
    context,
    boxType,
    key,
    media,
    imageAspectRatio = "16-9",
    padding,
    title,
    subtitle,
    text,
    view,
    trackRfrridClick,
    id,
    isAboveTheFold,
  } = useRenderableContext();

  const paddingTopValueContent = (title || subtitle) && !isExpandoEnabled ? "two" : "unset";

  const isBlossomLightGrey = boxType === "blossom-light-grey";
  const spacing: EGDSSpacingProps = isBlossomLightGrey ? { padding: { inline: "twelve", block: "six" } } : {};
  const backgroundTheme = isBlossomLightGrey ? "secondary" : undefined;

  const expandoKeyboardPress = (e: React.KeyboardEvent) => {
    //button elements should allow click on enter or space when a keydown event occurs. Allow by default when a mouse event.
    if (e.key === "Enter" || e.key === " ") {
      flipToggleStatus();
    }
  };

  return (
    <ConditionalLazyLoad isAboveTheFold={isAboveTheFold} context={context}>
      <EGDSCard
        backgroundTheme={backgroundTheme}
        className={boxType === "blossom-plain" ? "noBackground" : ""}
        border={shouldRenderBorder}
        key={key}
      >
        {media?.mediaUrl && (
          <EGDSFigure ratio={uitkFigureAspectRatio(imageAspectRatio)}>
            <BlossomImage src={media.mediaUrl || ""} alt={media.mediaAlt || ""} />
          </EGDSFigure>
        )}
        {isExpandoEnabled && (
          <div
            role="button"
            tabIndex={0}
            aria-expanded={!toggleStatus}
            className="header"
            onClick={flipToggleStatus}
            onKeyDown={expandoKeyboardPress}
          >
            <EGDSCardContentSection padded={padding !== "none"} className={`${toggleStatus && "allBorderZero"}`}>
              <EGDSLayoutFlex justifyContent="end">
                <EGDSHeading tag="h2" size={4}>
                  {title}
                </EGDSHeading>
                <EGDSLayoutFlexItem>
                  <div>
                    <EGDSSpacing margin={{ blockstart: "six" }}>
                      <EGDSSubheading tag="h3">{subtitle}</EGDSSubheading>
                    </EGDSSpacing>
                  </div>
                </EGDSLayoutFlexItem>
                <EGDSLayoutFlexItem>
                  <EGDSIcon name={`expand_${toggleStatus ? "more" : "less"}`} />
                </EGDSLayoutFlexItem>
              </EGDSLayoutFlex>
            </EGDSCardContentSection>
          </div>
        )}
        <div
          data-testid="editorial-content"
          className={`editorialContent ${isExpandoEnabled && toggleStatus ? "is-visually-hidden" : ""}`}
        >
          <EGDSCardContentSection padded={padding !== "none"}>
            <EGDSSpacing {...spacing}>
              <EGDSLayoutGrid>
                <EGDSLayoutGridItem>
                  <div className={displayEditorialClassNames} data-fm-content={id} id={id}>
                    {title && !isExpandoEnabled && (
                      <EGDSHeading tag="h2" size={4}>
                        {title}
                      </EGDSHeading>
                    )}
                    {subtitle && !isExpandoEnabled && <EGDSSubheading tag="h4">{subtitle}</EGDSSubheading>}
                    {text && (
                      <EGDSSpacing
                        padding={{ blockstart: paddingTopValueContent, blockend: "two", inlinestart: "one" }}
                      >
                        <EGDSText size={300} weight="regular">
                          <div
                            onClick={trackRfrridClick && trackRfrridClick(useClickTracker())}
                            className={`${view === "single-column" ? "singleColumn" : "editorialTextColumn"} ${
                              isBlossomLightGrey ? "blossomLightGrey" : ""
                            }`}
                          >
                            <DecorateEditorialHtml text={text} />
                          </div>
                        </EGDSText>
                      </EGDSSpacing>
                    )}
                  </div>
                </EGDSLayoutGridItem>
              </EGDSLayoutGrid>
            </EGDSSpacing>
          </EGDSCardContentSection>
        </div>
      </EGDSCard>
    </ConditionalLazyLoad>
  );
};
