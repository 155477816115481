import { DecorateEditorialHtml, returnCitiCode } from "src/components/flexComponents/Editorial/ContentUtilities";
import * as React from "react";
import { useLocation } from "react-router";
import { EGDSLayoutGrid, EGDSLayoutGridItem } from "@egds/react-core/layout-grid";
import { EGDSText } from "@egds/react-core/text";
import { EGDSPrimaryButton } from "@egds/react-core/button";
import { useLocalization } from "@shared-ui/localization-context";
import { EditorialModel } from "src/typings/microserviceModels/content-flex-module";
import { cardLink } from "stores/wizard/config/overrideConfigBySiteId/siteIds/siteId1";
import { EGDSSpacing } from "@egds/react-core/spacing";

export const CitiCardModalDialogPopUp = (props: { items: EditorialModel[] }) => {
  const { search: searchUrl } = useLocation();
  const { formatText } = useLocalization();
  const CONTENT_ID_COUNT = 8;
  const { items } = props;

  if (items.length !== CONTENT_ID_COUNT) {
    return null;
  }

  const [
    chooseCardText,
    feeCardImage,
    feeCardSubText,
    feeCardOfferText,
    seperator,
    noFeeCardImage,
    noFeeCardSubText,
    noFeeCardOfferText,
  ] = items.map((item) => <DecorateEditorialHtml key={item.id} text={item.text} />);
  const applyNowText = formatText("grid3.citiDesktopStickybar.applyNow.text");
  const citiCode = returnCitiCode(searchUrl);
  const feeCardApplyNowLink = citiCode ? cardLink.feeCardLink + `&citiCode=${citiCode}` : cardLink.feeCardLink;
  const noFeeCardApplyNowLink = citiCode ? cardLink.noFeeCardLink + `&citiCode=${citiCode}` : cardLink.noFeeCardLink;

  return (
    <EGDSLayoutGrid rows={1} space="six">
      <EGDSLayoutGridItem>
        <EGDSText align="center" size={600} weight="bold">
          <div>{chooseCardText}</div>
        </EGDSText>
      </EGDSLayoutGridItem>
      <EGDSLayoutGridItem>
        <div>
          <EGDSSpacing padding={{ blockend: "three" }}>
            <EGDSText align="center">
              <div>{feeCardImage}</div>
            </EGDSText>
          </EGDSSpacing>
          <EGDSText align="center" size={300} weight="bold">
            <div>{feeCardSubText}</div>
          </EGDSText>
          <EGDSSpacing padding={{ blockend: "three" }}>
            <EGDSText align="center" size={200} weight="bold">
              <div>{feeCardOfferText}</div>
            </EGDSText>
          </EGDSSpacing>
          <EGDSText align="center">
            <EGDSPrimaryButton type="button" href={feeCardApplyNowLink}>
              {applyNowText}
            </EGDSPrimaryButton>
          </EGDSText>
        </div>
      </EGDSLayoutGridItem>
      <EGDSLayoutGridItem>
        <EGDSText align="center">
          <div>{seperator}</div>
        </EGDSText>
      </EGDSLayoutGridItem>
      <EGDSLayoutGridItem>
        <div>
          <EGDSSpacing padding={{ blockend: "three" }}>
            <EGDSText align="center">
              <div>{noFeeCardImage}</div>
            </EGDSText>
          </EGDSSpacing>
          <EGDSText align="center" size={300} weight="bold">
            <div>{noFeeCardSubText}</div>
          </EGDSText>
          <EGDSSpacing padding={{ blockend: "three" }}>
            <EGDSText align="center" size={200} weight="bold">
              <div>{noFeeCardOfferText}</div>
            </EGDSText>
          </EGDSSpacing>
          <EGDSText align="center">
            <EGDSPrimaryButton type="button" href={noFeeCardApplyNowLink}>
              {applyNowText}
            </EGDSPrimaryButton>
          </EGDSText>
        </div>
      </EGDSLayoutGridItem>
    </EGDSLayoutGrid>
  );
};
export default CitiCardModalDialogPopUp;
