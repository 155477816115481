import * as React from "react";
import { DecorateEditorialHtml } from "src/components/flexComponents/Editorial/ContentUtilities";
import { EGDSText } from "@egds/react-core/text";
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { EGDSLayoutGrid, EGDSLayoutGridItem } from "@egds/react-core/layout-grid";
import { EditorialModel } from "src/typings/microserviceModels/content-flex-module";
import { EGDSSpacing } from "@egds/react-core/spacing";

const CONTENT_ID_COUNT = 6;
export const CardComparisonBenefitsEditorial = (props: { items: EditorialModel[] }) => {
  const { items } = props;

  if (items.length !== CONTENT_ID_COUNT) {
    return null;
  }

  const [
    benefitsIconOne,
    benefitsContentOne,
    benefitsIconTwo,
    benefitsContentTwo,
    benefitsIconThree,
    benefitsContentThree,
  ] = React.useMemo(() => items.map((item) => <DecorateEditorialHtml key={item.id} text={item.text} />), []);

  return (
    <EGDSSpacing
      padding={{ medium: { inline: "twelve" }, large: { inline: "unset", inlinestart: "twelve" } }}
      margin={{ medium: { inline: "twelve" }, large: { inline: "unset", inlinestart: "six" } }}
    >
      <div>
        <EGDSLayoutGrid>
          <EGDSSpacing padding={{ blockstart: "two" }}>
            <EGDSLayoutGridItem>
              <EGDSLayoutFlex space="three" alignItems="center">
                <EGDSLayoutFlexItem>
                  <div>{benefitsIconOne}</div>
                </EGDSLayoutFlexItem>
                <EGDSLayoutFlexItem>
                  <div>
                    <EGDSText size={400} weight="bold" align="left">
                      <div>{benefitsContentOne}</div>
                    </EGDSText>
                  </div>
                </EGDSLayoutFlexItem>
              </EGDSLayoutFlex>
            </EGDSLayoutGridItem>
          </EGDSSpacing>
          <EGDSSpacing padding={{ blockstart: "two" }}>
            <EGDSLayoutGridItem>
              <EGDSLayoutFlex space="three" alignItems="center">
                <EGDSLayoutFlexItem>
                  <div>{benefitsIconTwo}</div>
                </EGDSLayoutFlexItem>
                <EGDSLayoutFlexItem>
                  <div>
                    <EGDSText size={400} weight="bold" align="left">
                      <div>{benefitsContentTwo}</div>
                    </EGDSText>
                  </div>
                </EGDSLayoutFlexItem>
              </EGDSLayoutFlex>
            </EGDSLayoutGridItem>
          </EGDSSpacing>
          <EGDSSpacing padding={{ blockstart: "two" }}>
            <EGDSLayoutGridItem>
              <EGDSLayoutFlex space="three" alignItems="center">
                <EGDSLayoutFlexItem>
                  <div>{benefitsIconThree}</div>
                </EGDSLayoutFlexItem>
                <EGDSLayoutFlexItem>
                  <div>
                    <EGDSText size={400} weight="bold" align="left">
                      <div>{benefitsContentThree}</div>
                    </EGDSText>
                  </div>
                </EGDSLayoutFlexItem>
              </EGDSLayoutFlex>
            </EGDSLayoutGridItem>
          </EGDSSpacing>
        </EGDSLayoutGrid>
      </div>
    </EGDSSpacing>
  );
};

export default CardComparisonBenefitsEditorial;
