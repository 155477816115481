import * as React from "react";
import { EGDSSheet, EGDSSheetTransition } from "@egds/react-core/sheet";
import { EGDSToolbar } from "@egds/react-core/toolbar";
import { useDialog } from "@shared-ui/dialog-context";
import { useLocalization } from "@shared-ui/localization-context";
import CitiCardModalDialogPopUp from "./CitiCardModalDialogPopUp";
import { EGDSLayoutPosition } from "@egds/react-core/layout-position";
import { Viewport, ViewMedium, ViewSmall } from "@shared-ui/viewport-context";
import { EGDSScrim, EGDSScrimType } from "@egds/react-core/scrim";
import { EGDSPrimaryButton, EGDSButtonSize } from "@egds/react-core/button";
import { EditorialModel } from "src/typings/microserviceModels/content-flex-module";
import { EGDSSpacing } from "@egds/react-core/spacing";

export interface CitiCardModalDialogProps {
  items: EditorialModel[];
}

const CONTENT_ID_COUNT = 8;

const CitiCardModalDialog = (props: CitiCardModalDialogProps) => {
  const { items } = props;
  const [isDialogOpen, dialogActions, CitiCardDialogComponent] = useDialog("dialog-example-4");
  const { formatText } = useLocalization();
  const triggerRef = React.createRef<HTMLElement>();

  if (items.length !== CONTENT_ID_COUNT) {
    return null;
  }

  const applyNowText = formatText("grid3.citiDesktopStickybar.applyNow.text");

  return (
    <EGDSSpacing padding={{ blockstart: "six", blockend: "twelve" }}>
      <div>
        <Viewport>
          <ViewSmall>
            <>
              <EGDSSheetTransition isVisible={isDialogOpen}>
                <CitiCardDialogComponent>
                  <EGDSSheet type="full" triggerRef={triggerRef} isVisible={isDialogOpen}>
                    <EGDSToolbar
                      key="EGDSToolbar"
                      navigationContent={{
                        navIconLabel: "Close the dialog",
                        onClick: dialogActions.closeDialog as any,
                      }}
                    />
                    <div key="Citi-Card-modal-dialog-content-Paragraph">
                      <CitiCardModalDialogPopUp items={items} />
                    </div>
                  </EGDSSheet>
                </CitiCardDialogComponent>
              </EGDSSheetTransition>
              <EGDSLayoutPosition
                position={{ right: "zero", bottom: "zero", left: "zero", zindex: "layer3" }}
                className="uitk-button-floating-action-fixed"
              >
                <EGDSScrim type={EGDSScrimType.BUTTON}>
                  <EGDSPrimaryButton isFullWidth size={EGDSButtonSize.LARGE} onClick={dialogActions.openDialog as any}>
                    {applyNowText}
                  </EGDSPrimaryButton>
                </EGDSScrim>
              </EGDSLayoutPosition>
            </>
          </ViewSmall>
          <ViewMedium />
        </Viewport>
      </div>
    </EGDSSpacing>
  );
};

export default CitiCardModalDialog;
