import * as React from "react";
import EditorialBase from "components/flexComponents/Editorial/factory/EditorialBase";
import { EGDSHeading, EGDSSubheading } from "@egds/react-core/text";
import { EGDSCard } from "@egds/react-core/cards";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { ContentProps, EditorialFactoryProps } from "components/flexComponents/Editorial/typings";
import MediaDisplay from "../items/MediaDisplay";

export class StandaloneMediaEditorial extends EditorialBase {
  public constructor(editorial: EditorialFactoryProps) {
    super(editorial);
  }

  /**
   * An Editorial consists of HTML strings that we parse through with the best of
   * our abilities and display as is on the page. Mainly, we do not control the
   * content of an Editorial, we simply put them in the page.
   */
  public generate(props: ContentProps) {
    const { title: moduleTitle, subtitle: moduleSubtitle } = this.editorial;
    const { id, title, media } = this.editorial.items[0];

    if (!media || media.length === 0) {
      return null;
    }

    return (
      <EGDSSpacing padding={{ block: "three" }}>
        <div id={this.id} data-fm={this.fmId} data-fm-title-id={this.fmTitleId} className="StandaloneMediaEditorial">
          <>
            {moduleTitle && (
              <EGDSSpacing margin={{ blockend: "three" }}>
                <div>
                  <EGDSHeading tag="h2" size={3}>
                    {moduleTitle}
                  </EGDSHeading>
                  {moduleSubtitle && <EGDSSubheading>{moduleSubtitle}</EGDSSubheading>}
                </div>
              </EGDSSpacing>
            )}
          </>
          <EGDSCard border>
            <MediaDisplay id={id} media={media} title={title} />
          </EGDSCard>
        </div>
      </EGDSSpacing>
    );
  }
}

export default StandaloneMediaEditorial;
