import * as React from "react";
import { EditorialFactoryProps } from "../../../typings";
import EditorialBase from "../../EditorialBase";
import { ConfidenceMsgGridEditorial } from "./ConfidenceMsgGridEditorial";

export class Grid4 extends EditorialBase {
  /* istanbul ignore next */
  constructor(editorial: EditorialFactoryProps) {
    super(editorial);
  }

  public generate() {
    return (
      <ConfidenceMsgGridEditorial
        id={this.id}
        model={this.editorial}
        confidenceMsgClassName="custom-confidence-msg-layout"
      />
    );
  }
}
