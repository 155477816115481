import { ItemKeyHelper } from "components/utility/ItemKeyHelper";
import * as React from "react";
import { EGDSLayoutGrid, EGDSLayoutGridColumnRow } from "@egds/react-core/layout-grid";
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { EditorialFlexModule } from "../../../../../../typings/microserviceModels/content-flex-module";
import { ConfidenceMsgGridItem } from "../items/ConfidenceMsgGridItem";

const gridColumns = {
  extra_large: 4 as EGDSLayoutGridColumnRow,
  large: 4 as EGDSLayoutGridColumnRow,
  medium: 2 as EGDSLayoutGridColumnRow,
};

export const ConfidenceMsgGridEditorial = ({
  id,
  model,
  confidenceMsgClassName,
}: {
  id: string;
  model: EditorialFlexModule;
  confidenceMsgClassName: string;
}) => {
  const { items, boxType } = model;

  if (items.length !== 4) {
    return null;
  }

  const keyHelper = new ItemKeyHelper("editorial");

  const renderGridItems = () => {
    return items.map((item) => {
      if (!item.text) {
        return null;
      }

      const [icon, title, content] = item.text.split("<br />");

      return (
        <div key={item.id}>
          <EGDSLayoutFlex direction="column" alignContent="center" blockSize="full_size">
            <EGDSLayoutFlexItem key={keyHelper.next()} alignSelf="center">
              <div data-fm-content={item.id}>
                <ConfidenceMsgGridItem
                  id={model.id}
                  text={icon}
                  boxType={boxType}
                  weight={!content ? "bold" : undefined}
                />
              </div>
            </EGDSLayoutFlexItem>
            <EGDSLayoutFlexItem key={keyHelper.next()}>
              <div data-fm-content={item.id}>
                <ConfidenceMsgGridItem
                  id={model.id}
                  text={title}
                  boxType={boxType}
                  weight={content ? "bold" : "regular"}
                />
                <ConfidenceMsgGridItem id={model.id} text={content} boxType={boxType} weight="regular" />
              </div>
            </EGDSLayoutFlexItem>
          </EGDSLayoutFlex>
        </div>
      );
    });
  };

  return (
    <div id={id} data-testid="confidence-msg-grid-editorial">
      <EGDSLayoutGrid columns={gridColumns} space="three" alignItems="center" className={confidenceMsgClassName}>
        {renderGridItems()}
      </EGDSLayoutGrid>
    </div>
  );
};
