import { EGDSHeading, EGDSSubheading, EGDSText } from "@egds/react-core/text";
import { EGDSFigure } from "@egds/react-core/images";
import { EGDSIcon } from "@egds/react-core/icons";
import * as React from "react";
import { DecorateEditorialHtml } from "src/components/flexComponents/Editorial/ContentUtilities";
import { uitkFigureAspectRatio } from "../editorials/EditorialWithLink";
import { LazyLoad } from "src/components/utility/LazyLoad/LazyLoad";
import { BlossomImage } from "src/components/shared/BlossomImage/BlossomImage";
import { EGDSLayoutGrid, EGDSLayoutGridItem } from "@egds/react-core/layout-grid";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { useEgClickstream, useClickTracker } from "@shared-ui/clickstream-analytics-context";
import { ModuleViewType } from "components/flexComponents/Editorial/factory/EditorialContentFactory";
import { useRenderableContext } from "./DefaultEditorial";
import { EGDSLink } from "@egds/react-core/link";
import { RenderableContentCardProps } from "./RenderableContentCard";
import { EGDSCard, EGDSCardLink } from "@egds/react-core/cards";
import { SpacingSize } from "src/components/utility/FlexAttributesUtil";

/**
 * Editorial On Canvas component that sits the content directly
 * in the DOM (without a card).
 *
 * IMPORTANT: As of May 17th 2021, we agreed on adding a workaround
 * placing <UitkCard/> as its comp's parent element to support
 * rounded corner image using existing Uitk classes, and <UitkCardLink/>
 * to support the whole card clickable. All this to not adding more custom
 * CSS to blossom's layout. This is just TEMPORARY and it is not going to be
 * permanent, this was already discussed with EGDS and we are going to contribute
 * to UITK for round corners in images once it is in EGDS.
 * More info in: https://expedia.slack.com/archives/C88UW0PU7/p1621006460220000
 *
 *
 */

interface CollapsableContentProps extends Pick<RenderableContentCardProps, "toggleStatus" | "flipToggleStatus"> {}
interface ContentSectionProps extends Pick<RenderableContentCardProps, "displayEditorialClassNames"> {
  isExpandoEnabled?: boolean | string;
}
interface RenderableContentOnCanvasProps extends RenderableContentCardProps {}

export const RenderableContentOnCanvas = ({
  isExpandoEnabled,
  toggleStatus,
  flipToggleStatus,
  displayEditorialClassNames,
}: RenderableContentOnCanvasProps) => {
  const { title, context, key, media } = useRenderableContext();
  const track = useEgClickstream();
  const mediaUrl = media?.clickUrl;
  const mediaCaption = media?.mediaCaption;
  const refId = media?.refId;

  const sendSelectedEvent = () => {
    if (refId) {
      const [eventName, action_location] = refId.split("/");

      track({
        event: {
          event_version: "1.0.0",
          event_name: `${eventName}.selected`,
          event_type: "Interaction",
          action_location,
        },
      });
    }
  };

  return (
    <LazyLoad context={context}>
      <EGDSCard onClick={sendSelectedEvent} className="noBackground cardWithLinkText">
        <EGDSCardLink>
          <a href={mediaUrl}>{mediaCaption || title}</a>
        </EGDSCardLink>
        <EGDSLayoutGrid key={key}>
          {media?.mediaUrl && <MediaContent />}
          {isExpandoEnabled && <CollapsableContent toggleStatus={toggleStatus} flipToggleStatus={flipToggleStatus} />}
          <div
            data-testid="editorial-content"
            className={`editorialContent ${isExpandoEnabled && toggleStatus ? "is-visually-hidden" : ""}`}
          >
            <ContentSection
              isExpandoEnabled={isExpandoEnabled}
              displayEditorialClassNames={displayEditorialClassNames}
            />
          </div>
        </EGDSLayoutGrid>
      </EGDSCard>
    </LazyLoad>
  );
};

const CollapsableContent: React.FC<CollapsableContentProps> = ({ toggleStatus, flipToggleStatus }) => {
  const { title, subtitle } = useRenderableContext();

  const expandoKeyboardPress = (e: React.KeyboardEvent) => {
    //button elements should allow click on enter or space when a keydown event occurs. Allow by default when a mouse event.
    if (e.key === "Enter" || e.key === " ") {
      flipToggleStatus();
    }
  };
  return (
    <div
      tabIndex={0}
      role="button"
      aria-expanded={!toggleStatus}
      className="header"
      onClick={flipToggleStatus}
      onKeyDown={expandoKeyboardPress}
    >
      <EGDSLayoutGrid columns={2}>
        <EGDSLayoutGridItem colSpan={1}>
          <EGDSHeading tag="h2" size={4}>
            {title}
          </EGDSHeading>
        </EGDSLayoutGridItem>
        <EGDSLayoutGridItem colSpan={1}>
          <EGDSLayoutFlex justifyContent="end">
            <EGDSLayoutFlexItem>
              <div>
                <EGDSSpacing margin={{ blockstart: "six" }}>
                  <EGDSSubheading tag="h3">{subtitle}</EGDSSubheading>
                </EGDSSpacing>
              </div>
            </EGDSLayoutFlexItem>
            <EGDSLayoutFlexItem>
              <EGDSIcon name={`expand_${toggleStatus ? "more" : "less"}`} />
            </EGDSLayoutFlexItem>
          </EGDSLayoutFlex>
        </EGDSLayoutGridItem>
      </EGDSLayoutGrid>
    </div>
  );
};

const ContentSection: React.FC<ContentSectionProps> = ({ isExpandoEnabled, displayEditorialClassNames }) => {
  const { title, subtitle, text, media, view, trackRfrridClick, padding, id } = useRenderableContext();

  const mediaUrl = media?.clickUrl;
  const mediaCaption = media?.mediaCaption;
  const withPadding = padding !== SpacingSize.NONE;

  const isSingleColumn = view === ModuleViewType.SingleColumnOnCanvas || ModuleViewType.OnCanvasBanner;
  const isOnCanvasBanner = view === ModuleViewType.OnCanvasBanner;

  const showClickableMedia = mediaUrl && mediaCaption && !isOnCanvasBanner;

  const contentHeading = !isOnCanvasBanner ? (
    <EGDSHeading tag="h2" size={4}>
      {title}
    </EGDSHeading>
  ) : (
    <EGDSText size={200}>{title}</EGDSText>
  );
  const contentSubtitle = !isOnCanvasBanner ? (
    <EGDSSubheading>{subtitle}</EGDSSubheading>
  ) : (
    <EGDSHeading tag="h2" size={6}>
      {subtitle}
    </EGDSHeading>
  );

  const textClassNames = isSingleColumn ? "singleColumn" : "editorialTextColumn";

  return (
    <EGDSSpacing padding={withPadding ? { block: "three" } : "unset"}>
      <div>
        <EGDSLayoutGridItem>
          <div className={displayEditorialClassNames} data-fm-content={id} id={id}>
            {title && !isExpandoEnabled && (
              <EGDSSpacing padding={isOnCanvasBanner ? { blockend: "one" } : "unset"}>{contentHeading}</EGDSSpacing>
            )}
            {subtitle && !isExpandoEnabled && contentSubtitle}
            {text && (
              <EGDSSpacing padding={withPadding ? { blockstart: "two" } : "unset"}>
                <EGDSText size={300} weight="regular">
                  <div onClick={trackRfrridClick && trackRfrridClick(useClickTracker())} className={textClassNames}>
                    <DecorateEditorialHtml text={text} />
                  </div>
                </EGDSText>
              </EGDSSpacing>
            )}
            {showClickableMedia && (
              <EGDSSpacing margin={{ blockstart: "four" }} padding="unset">
                <EGDSLink aria-hidden="true">
                  <a href={mediaUrl} data-testid="editorial-media-caption-link">
                    {mediaCaption}
                  </a>
                </EGDSLink>
              </EGDSSpacing>
            )}
          </div>
        </EGDSLayoutGridItem>
      </div>
    </EGDSSpacing>
  );
};

const MediaContent: React.FC = () => {
  const { media, imageAspectRatio = "16-9" } = useRenderableContext();

  return (
    <EGDSLayoutGridItem>
      <div data-testid="image-media-content" aria-hidden="true">
        <EGDSFigure className="uitk-card-roundcorner-all" ratio={uitkFigureAspectRatio(imageAspectRatio)}>
          <BlossomImage src={media?.mediaUrl || ""} alt={media?.mediaAlt || ""} imageRatio={imageAspectRatio} noCrop />
        </EGDSFigure>
      </div>
    </EGDSLayoutGridItem>
  );
};
