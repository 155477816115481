import * as React from "react";
import { EditorialModel } from "src/typings/microserviceModels/content-flex-module";
import { EGDSText } from "@egds/react-core/text";
import { DecorateEditorialHtml } from "src/components/flexComponents/Editorial/ContentUtilities";
import { EGDSSpacing } from "@egds/react-core/spacing";

export const CitiCardTierText = (props: { items: EditorialModel[] }) => {
  const { items } = props;
  if (items.length === 0 || items.length !== 1) {
    return null;
  }

  return (
    <div>
      <EGDSText align="center" size={400} weight="bold">
        <EGDSSpacing padding={{ blockend: "two" }}>
          <DecorateEditorialHtml text={items[0].text} />
        </EGDSSpacing>
      </EGDSText>
    </div>
  );
};

export default CitiCardTierText;
