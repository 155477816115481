import * as React from "react";
import { DecorateEditorialHtml } from "src/components/flexComponents/Editorial/ContentUtilities";
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { EGDSLayoutGrid, EGDSLayoutGridItem } from "@egds/react-core/layout-grid";
import { EGDSText } from "@egds/react-core/text";
import { EditorialModel } from "src/typings/microserviceModels/content-flex-module";
import { EGDSSpacing } from "@egds/react-core/spacing";

export const CitiContent = (props: { items: EditorialModel[] }) => {
  const { items } = props;
  const maxItemsAllowed = 2;

  if (items.length !== maxItemsAllowed) {
    return null;
  }

  return (
    <EGDSLayoutFlex space="two" wrap="wrap" justifyContent="center">
      <EGDSLayoutFlexItem maxWidth="one_hundred_twenty">
        <EGDSLayoutGrid rows={1}>
          <EGDSLayoutGridItem>
            <div>
              <EGDSText align="left" size={200}>
                <EGDSSpacing padding={{ blockstart: "twelve" }}>
                  <DecorateEditorialHtml text={items[0].text} />
                </EGDSSpacing>
              </EGDSText>
            </div>
          </EGDSLayoutGridItem>

          <EGDSLayoutGridItem>
            <div>
              <EGDSText align="left" size={200}>
                <EGDSSpacing padding={{ blockend: "twelve" }}>
                  <DecorateEditorialHtml text={items[1].text} />
                </EGDSSpacing>
              </EGDSText>
            </div>
          </EGDSLayoutGridItem>
        </EGDSLayoutGrid>
      </EGDSLayoutFlexItem>
    </EGDSLayoutFlex>
  );
};

export default CitiContent;
