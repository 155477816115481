import * as React from "react";
import {
  EGDSLayoutGrid,
  EGDSLayoutGridItem,
  EGDSLayoutGridColumnRow,
  EGDSLayoutGridSpan,
} from "@egds/react-core/layout-grid";
import { EGDSText } from "@egds/react-core/text";
import { EGDSIcon, EGDSIconSize } from "@egds/react-core/icons";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { DecorateEditorialHtml } from "src/components/flexComponents/Editorial/ContentUtilities";
import { EditorialModel } from "src/typings/microserviceModels/content-flex-module";

export const CitiTripleDipIconSectionWithAdd = (props: { items: EditorialModel[] }) => {
  const { items } = props;
  if (items.length !== 3) {
    return null;
  }

  const IconColSpan = {
    small: 1 as EGDSLayoutGridSpan,
    medium: 1 as EGDSLayoutGridSpan,
    large: 5 as EGDSLayoutGridSpan,
    extra_large: 5 as EGDSLayoutGridSpan,
  };

  const gridColumns = {
    extra_large: 6 as EGDSLayoutGridColumnRow,
    large: 6 as EGDSLayoutGridColumnRow,
    medium: 1 as EGDSLayoutGridColumnRow,
    small: 1 as EGDSLayoutGridColumnRow,
  };

  return (
    <EGDSLayoutGrid columns={gridColumns} space="four">
      <EGDSLayoutGridItem colSpan={IconColSpan}>
        <EGDSSpacing padding={{ inlinestart: "three" }}>
          <EGDSLayoutGrid>
            <div>
              <EGDSText align="center">
                <EGDSSpacing padding={{ blockend: "three" }}>
                  <DecorateEditorialHtml text={items[0].text} />
                </EGDSSpacing>
              </EGDSText>
              <EGDSText align="center" size={500} weight="bold">
                <EGDSSpacing padding={{ block: "half" }}>
                  <DecorateEditorialHtml text={items[1].text} />
                </EGDSSpacing>
              </EGDSText>
              <EGDSText align="center" size={300}>
                <EGDSSpacing padding={{ block: "half" }}>
                  <DecorateEditorialHtml text={items[2].text} />
                </EGDSSpacing>
              </EGDSText>
            </div>
          </EGDSLayoutGrid>
        </EGDSSpacing>
      </EGDSLayoutGridItem>
      <EGDSLayoutGridItem colSpan={1}>
        <div>
          <EGDSSpacing padding={{ blockstart: "four", inlinestart: "two" }}>
            <EGDSText align="center">
              <EGDSSpacing padding={{ blockend: "six" }}>
                <EGDSIcon name="add" size={EGDSIconSize.LARGE} />
              </EGDSSpacing>
            </EGDSText>
          </EGDSSpacing>
        </div>
      </EGDSLayoutGridItem>
    </EGDSLayoutGrid>
  );
};
export default CitiTripleDipIconSectionWithAdd;
