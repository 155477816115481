import {
  EGDSLayoutGrid,
  EGDSLayoutGridItem,
  EGDSLayoutGridColumnRow,
  EGDSLayoutGridSpan,
} from "@egds/react-core/layout-grid";
import { EGDSText } from "@egds/react-core/text";
import * as React from "react";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { DecorateEditorialHtml } from "src/components/flexComponents/Editorial/ContentUtilities";
import { EditorialModel } from "src/typings/microserviceModels/content-flex-module";

export const CitiTripleDipIconSectionWithoutAdd = (props: { items: EditorialModel[] }) => {
  const { items } = props;
  if (!items || items.length !== 3) {
    return null;
  }

  const IconColSpan = {
    small: 1 as EGDSLayoutGridSpan,
    medium: 2 as EGDSLayoutGridSpan,
    large: 2 as EGDSLayoutGridSpan,
    extra_large: 2 as EGDSLayoutGridSpan,
  };

  const gridColumns = {
    small: 1 as EGDSLayoutGridColumnRow,
    medium: 2 as EGDSLayoutGridColumnRow,
    large: 2 as EGDSLayoutGridColumnRow,
    extra_large: 2 as EGDSLayoutGridColumnRow,
  };

  return (
    <EGDSLayoutGrid columns={gridColumns} space="six">
      <EGDSLayoutGridItem colSpan={IconColSpan} rowSpan={3}>
        <EGDSSpacing padding={{ inlinestart: "four" }}>
          <EGDSLayoutGrid space="one">
            <div>
              <EGDSText align="center">
                <EGDSSpacing padding={{ blockend: "three" }}>
                  <DecorateEditorialHtml text={items[0].text} />
                </EGDSSpacing>
              </EGDSText>
              <EGDSText align="center" size={500} weight="bold">
                <EGDSSpacing padding={{ block: "half" }}>
                  <DecorateEditorialHtml text={items[1].text} />
                </EGDSSpacing>
              </EGDSText>
              <EGDSText align="center" size={300}>
                <EGDSSpacing padding={{ block: "half" }}>
                  <DecorateEditorialHtml text={items[2].text} />
                </EGDSSpacing>
              </EGDSText>
            </div>
          </EGDSLayoutGrid>
        </EGDSSpacing>
      </EGDSLayoutGridItem>
    </EGDSLayoutGrid>
  );
};
export default CitiTripleDipIconSectionWithoutAdd;
