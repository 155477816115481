import { EditorialFactoryProps } from "components/flexComponents/Editorial/typings";
import * as React from "react";
import EditorialBase from "components/flexComponents/Editorial/factory/EditorialBase";
import { EditorialModel, Media } from "typings/microserviceModels/content-flex-module";
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { ImageEditorialWithLink } from "./ImageEditorialWithLink";
import { EGDSImage } from "@egds/react-core/images";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { EGDSText } from "@egds/react-core/text";

export class MultipleImages extends EditorialBase {
  /* istanbul ignore next */
  public constructor(editorial: EditorialFactoryProps) {
    super(editorial);
  }

  private generateImageComponents = (items: EditorialModel[]) =>
    items
      .filter((item: EditorialModel) => item.media && item.media.filter((media) => media.mediaUrl))
      .map((item: EditorialModel) => item.media)
      .reduce((item, acum) => acum && acum.concat.apply(acum, item), [])
      .sort((a: Media, b: Media) => a.mediaSeq - b.mediaSeq)
      .map(({ mediaUrl, mediaAlt, clickUrl }: Media) => (
        <EGDSLayoutFlexItem key={mediaUrl}>
          <div>
            {clickUrl ? (
              <ImageEditorialWithLink key={mediaUrl} mediaUrl={mediaUrl} mediaAlt={mediaAlt} clickUrl={clickUrl} />
            ) : (
              <EGDSSpacing padding={{ block: "two" }}>
                <div>
                  <EGDSImage key={mediaUrl} alt={mediaAlt} src={mediaUrl} className="images-size" />
                </div>
              </EGDSSpacing>
            )}
          </div>
        </EGDSLayoutFlexItem>
      ));

  /**
   * An Editorial consists of HTML strings that we parse through with the best of
   * our abilities and display as is on the page. Mainly, we do not control the
   * content of an Editorial, we simply put them in the page.
   */
  public generate() {
    return (
      <EGDSSpacing padding={{ blockstart: "six" }}>
        <EGDSLayoutFlex
          id={this.id}
          data-fm={this.fmId}
          data-fm-title-id={this.fmTitleId}
          direction="column"
          className="editorial-images-flex-container"
        >
          <EGDSLayoutFlexItem>
            <EGDSText align="center" size={200}>
              {this.editorial.items.map((item) => item.title)}
            </EGDSText>
          </EGDSLayoutFlexItem>
          <EGDSLayoutFlex justifyContent="center">
            <EGDSLayoutFlexItem>
              <div>
                <EGDSLayoutFlex justifyContent="center" space="twelve">
                  {this.generateImageComponents(this.editorial.items)}
                </EGDSLayoutFlex>
              </div>
            </EGDSLayoutFlexItem>
          </EGDSLayoutFlex>
        </EGDSLayoutFlex>
      </EGDSSpacing>
    );
  }
}

export default MultipleImages;
