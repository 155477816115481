import * as React from "react";
import { EGDSText, EGDSTextProps } from "@egds/react-core/text";
import { EGDSCard, EGDSCardContentSection } from "@egds/react-core/cards";
import { DecorateEditorialHtml } from "src/components/flexComponents/Editorial/ContentUtilities";

interface ConfidenceMsgGridItemProps {
  id: string;
  text: string;
  weight?: EGDSTextProps["weight"];
  boxType?: string;
}

export const ConfidenceMsgGridItem: React.FC<ConfidenceMsgGridItemProps> = ({ id, text, weight, boxType }) => {
  const useEGDSCard = boxType === "blossom-white";

  if (!text) {
    return null;
  }

  const mainContent = (
    <EGDSText align="center" weight={weight} size={500}>
      <div data-fm-content={id}>
        <DecorateEditorialHtml text={text} />
      </div>
    </EGDSText>
  );

  return useEGDSCard ? (
    <EGDSCard>
      <EGDSCardContentSection>{mainContent}</EGDSCardContentSection>
    </EGDSCard>
  ) : (
    mainContent
  );
};
