import * as React from "react";
import { EGDSUnformattedHtml, EGDSUnformattedHtmlReplaceDefaults } from "@egds/react-core/unformatted-html";
import { EGDSHeading, EGDSText } from "@egds/react-core/text";
import { EGDSList, EGDSListItem } from "@egds/react-core/list";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { EGDSTable, EGDSTableRow, EGDSTableCell, EGDSTableCellBorder } from "@egds/react-core/tables";
import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";
import { LazyLoad } from "components/utility/LazyLoad/LazyLoad";

type DecorateEditorialHtmlProps = { text: string | undefined };

export const DecorateEditorialHtml: React.FC<DecorateEditorialHtmlProps> = ({ text }) => {
  if (!text) {
    return null;
  }

  return (
    <EGDSUnformattedHtml
      elementsToReplace={{
        ...EGDSUnformattedHtmlReplaceDefaults,
        h1: ({ children, ...props }) => (
          <EGDSHeading tag="h1" {...props} size={4}>
            {children}
          </EGDSHeading>
        ),
        h2: ({ children, ...props }) => (
          <EGDSHeading tag="h2" {...props} size={4}>
            {children}
          </EGDSHeading>
        ),
        h3: ({ children, ...props }) => (
          <EGDSHeading tag="h3" {...props} size={6}>
            {children}
          </EGDSHeading>
        ),
        h4: ({ children, ...props }) => (
          <EGDSHeading tag="h4" {...props} size={6}>
            {children}
          </EGDSHeading>
        ),
        h5: ({ children, ...props }) => (
          <EGDSHeading tag="h5" {...props} size={5}>
            {children}
          </EGDSHeading>
        ),
        h6: ({ children, ...props }) => (
          <EGDSHeading tag="h6" {...props} size={5}>
            {children}
          </EGDSHeading>
        ),
        li: ({ children, ...props }) => <EGDSListItem {...props}>{children}</EGDSListItem>,
        ul: ({ children, ...props }) => (
          <EGDSSpacing margin={{ block: "two" }}>
            <EGDSList {...props}>{children}</EGDSList>
          </EGDSSpacing>
        ),
        ol: ({ children, ...props }) => (
          <EGDSSpacing margin={{ block: "two" }}>
            <EGDSList {...props} type="ordered">
              {children}
            </EGDSList>
          </EGDSSpacing>
        ),
        p: ({ children, ...props }) => <p {...props}>{children}</p>,
        a: ({ children, ...props }) => <a {...props}>{children}</a>,
        table: ({ children, ...props }) => (
          <EGDSTable border striped="odd" {...props}>
            <>{children}</>
          </EGDSTable>
        ),
        tr: ({ children, ...props }) => (
          <EGDSTableRow {...props}>
            <>{children}</>
          </EGDSTableRow>
        ),
        td: ({ children, ...props }) => (
          <EGDSTableCell border={[EGDSTableCellBorder.horizontal, EGDSTableCellBorder.vertical]} {...props}>
            {children}
          </EGDSTableCell>
        ),
        th: ({ children, ...props }) => (
          <EGDSTableCell border={[EGDSTableCellBorder.horizontal, EGDSTableCellBorder.vertical]} tag="th" {...props}>
            <EGDSText whiteSpace="normal">{children}</EGDSText>
          </EGDSTableCell>
        ),
      }}
      htmlToParse={text}
      plainTextElement={({ children }) => <div>{children}</div>}
    />
  );
};

// extracting the citiCode, if present in the current path and returning it and if not then, returning empty string.
export const returnCitiCode = (searchUrl: string) => {
  const INITIAL_INDEX = searchUrl.indexOf("citiCode") + 9; //to get citiCode value's beginning index in the searchUrl
  const FINAL_INDEX = searchUrl.indexOf("citiCode") + 12; //to get citiCode value's final index in the serachUrl
  return searchUrl.includes("citiCode") ? searchUrl.substring(INITIAL_INDEX, FINAL_INDEX) : "";
};

type EditorialShouldLazyLoad = {
  isAboveTheFold?: boolean;
  context?: ExtendedContextStore;
  children: React.ReactElement;
};

export const ConditionalLazyLoad = ({ isAboveTheFold, context, children }: EditorialShouldLazyLoad) =>
  isAboveTheFold ? <>{children}</> : <LazyLoad context={context}>{children}</LazyLoad>;
