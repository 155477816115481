import * as React from "react";
import { EGDSLink } from "@egds/react-core/link";
import { EGDSImage } from "@egds/react-core/images";
import { ImageMediaType } from "src/components/flexComponents/Editorial/typings";

export const ImageEditorialWithLink = ({ mediaUrl, mediaAlt, clickUrl }: ImageMediaType) => (
  <EGDSLink>
    <a href={clickUrl}>
      <EGDSImage
        key={mediaUrl}
        alt={mediaAlt}
        src={mediaUrl}
        placeholderImage
        className="images-size"
        lazyLoading="lazy"
      />
    </a>
  </EGDSLink>
);

export default ImageEditorialWithLink;
