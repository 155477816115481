import * as React from "react";
import { EGDSCard, EGDSCardContentSection } from "@egds/react-core/cards";
import { EGDSText } from "@egds/react-core/text";
import { EGDSIcon, EGDSIconSize } from "@egds/react-core/icons";
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { DecorateEditorialHtml } from "src/components/flexComponents/Editorial/ContentUtilities";
import { EditorialModel } from "src/typings/microserviceModels/content-flex-module";

export const CitifaqSection = (props: { items: EditorialModel[] }) => {
  const { items } = props;
  const [isVisible, setIsVisible] = React.useState(false);
  const toggle = React.useCallback(() => setIsVisible(!isVisible), [isVisible]);
  const expandoKeyboardPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" || e.key === " ") {
      toggle();
    }
  };

  if (items.length !== 2) {
    return null;
  }

  return (
    <EGDSLayoutFlex space="two" wrap="wrap" justifyContent="center">
      <EGDSLayoutFlexItem grow={1} minWidth="twenty_four" maxWidth="one_hundred_twenty">
        <EGDSSpacing padding={{ blockend: "three" }}>
          <div>
            <EGDSCard>
              <div
                className="CallToAction"
                tabIndex={0}
                role="button"
                aria-expanded={isVisible}
                onClick={toggle}
                data-testid="expando-toggle"
                onKeyDown={expandoKeyboardPress}
              >
                <EGDSCardContentSection padded>
                  <EGDSLayoutFlex>
                    <EGDSLayoutFlexItem grow={1}>
                      <EGDSText size={400} weight="bold" theme="default">
                        <EGDSSpacing margin={{ inlineend: "twelve" }}>
                          <DecorateEditorialHtml text={items[0].text} />
                        </EGDSSpacing>
                      </EGDSText>
                    </EGDSLayoutFlexItem>
                    <EGDSLayoutFlexItem alignSelf="center" grow={0}>
                      <EGDSIcon name={isVisible ? "expand_less" : "expand_more"} size={EGDSIconSize.SMALL} />
                    </EGDSLayoutFlexItem>
                  </EGDSLayoutFlex>
                </EGDSCardContentSection>
              </div>
              <EGDSCardContentSection
                padded={["blockend", "inlinestart", "inlineend"]}
                className={`${isVisible ? "" : "is-hidden"}`}
              >
                <EGDSSpacing padding={{ blockend: "one", inline: "one" }}>
                  <div>
                    <EGDSText size={200}>
                      <DecorateEditorialHtml text={items[1].text} />
                    </EGDSText>
                  </div>
                </EGDSSpacing>
              </EGDSCardContentSection>
            </EGDSCard>
          </div>
        </EGDSSpacing>
      </EGDSLayoutFlexItem>
    </EGDSLayoutFlex>
  );
};

export default CitifaqSection;
